/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import {
  DHLFieldNote,
  DHLFormGroup,
  DHLHeadline,
  DHLIcon,
  DHLRadioButton,
  DHLTextInput,
  DHLTextOutput,
  DHLTooltip,
  ResourceDataStore
} from "@gkuis/gkp-base-widgets";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { ResponseplusConstants } from "../../../../constants/ResponseplusConstants";
import { ResponseplusOrderStore } from "../../../../stores/ResponseplusOrderStore";
import "./OrderPage2ContentDetails.scss";

export type ResponseplusReturnAddressContentProps = {
  orderStore: ResponseplusOrderStore;
}

export const ResponseplusReturnAddressInput = observer((
    {
      orderStore: dataStore
    }: ResponseplusReturnAddressContentProps) => {

  const resourceDataStore: ResourceDataStore = dataStore.baseStore.resourceDataStore as ResourceDataStore;

  const standardInputs =
      <>
        <DHLFormGroup>
          <DHLTextInput formField={dataStore.addressCompanyNameFormField} />
        </DHLFormGroup>
        <DHLFormGroup>
          <DHLTextInput formField={dataStore.addressAddition1FormField} />
        </DHLFormGroup>
        <DHLFormGroup>
          <DHLTextInput formField={dataStore.addressAddition2FormField} />
        </DHLFormGroup>
      </>;

  const streetHouseNumberInputs =
      <DHLFormGroup className="row">
        <div className="col-9">
          <DHLTextInput formField={dataStore.addressStreetFormField} />
        </div>
        <div className="col-3">
          <DHLTextInput formField={dataStore.addressHouseNumberFormField} />
        </div>
      </DHLFormGroup>;

  const cityInputs =
      <DHLFormGroup className="row">
        <div className="col-3">
          <DHLTextInput formField={dataStore.addressPostcodeFormField} />
        </div>
        <div className="col-9">
          <DHLTextInput formField={dataStore.addressCityFormField} />
        </div>
      </DHLFormGroup>;

  const regularAddressContent: React.ReactElement =
      <DHLFormGroup>
        {standardInputs}
        {cityInputs}
        {streetHouseNumberInputs}
      </DHLFormGroup>;

  const poBoxContent: React.ReactElement =
      <DHLFormGroup>
        {standardInputs}
        <DHLFormGroup>
          <DHLTextInput formField={dataStore.addressPoBoxFormField} />
        </DHLFormGroup>
        {cityInputs}
      </DHLFormGroup>;

  const largeReceiverContent: React.ReactElement =
      <DHLFormGroup>
        {standardInputs}
        {cityInputs}
      </DHLFormGroup>;

  const freeTextContent: React.ReactElement =
      <DHLFormGroup>
        {standardInputs}
        <DHLFormGroup>
          <DHLTextInput formField={dataStore.addressAddition3FormField} />
        </DHLFormGroup>
        <DHLFormGroup>
          <DHLTextInput formField={dataStore.addressAddition4FormField} />
        </DHLFormGroup>
        <DHLFormGroup>
          <DHLTextInput formField={dataStore.addressAddition5FormField} />
        </DHLFormGroup>
        {cityInputs}
      </DHLFormGroup>;

  function renderReturnAddressContent(addressType: string) {
    switch (addressType) {
      case ResponseplusConstants.addressType.regular.toString():
        return regularAddressContent;
      case ResponseplusConstants.addressType.poBox.toString():
        return poBoxContent;
      case ResponseplusConstants.addressType.largeReceiver.toString():
        return largeReceiverContent;
      default: //addressType.freeInput
        return freeTextContent;
    }
  }

  const [addressTypeTooltipOpen, setAddressTypeTooltipOpen] = useState(false);
  const toggleAddressTypeTooltip = () => setAddressTypeTooltipOpen(!addressTypeTooltipOpen);

  const addressTypeInfoIcon =
      <>
        <DHLIcon name={"addressTypeInfoButton"} icon={"alert-info"} />
        <DHLTooltip target={"addressTypeInfoButton"} placement={"bottom"} tooltipOpen={addressTypeTooltipOpen} toggle={toggleAddressTypeTooltip}>
          {resourceDataStore.getLabel("order.page2-orderDetails.addressTypeTooltip")}
        </DHLTooltip>
      </>;

  return <DHLFormGroup>
    <DHLFormGroup>
      <DHLHeadline type={"h6"}>
        {resourceDataStore.getLabel("order.page2-orderDetails.returnAddressHeadline")}
      </DHLHeadline>
    </DHLFormGroup>
    <DHLFormGroup>
      <DHLTextOutput value={resourceDataStore.getLabel("order.page2-orderDetails.returnAddressText")} />
    </DHLFormGroup>
    <DHLFormGroup className={"radioButtonsAndIcon"}>
      <DHLFormGroup checkboxGroup inline>
        <DHLRadioButton selectValue={ResponseplusConstants.addressType.regular.toString()}
                        label={resourceDataStore.getLabel("order.page2-orderDetails.addressRegular")}
                        required={false}
                        formField={dataStore.returnAddressFormField}
                        errorMarkerOnly={true}
        />
        <DHLRadioButton selectValue={ResponseplusConstants.addressType.poBox.toString()}
                        label={resourceDataStore.getLabel("order.page2-orderDetails.addressPoBox")}
                        required={false}
                        formField={dataStore.returnAddressFormField}
                        errorMarkerOnly={true}
        />
        <DHLRadioButton selectValue={ResponseplusConstants.addressType.largeReceiver.toString()}
                        label={resourceDataStore.getLabel("order.page2-orderDetails.addressLargeReceiver")}
                        required={false}
                        formField={dataStore.returnAddressFormField}
                        errorMarkerOnly={true}
        />
        <DHLRadioButton selectValue={ResponseplusConstants.addressType.freeInput.toString()}
                        label={resourceDataStore.getLabel("order.page2-orderDetails.addressFreeInput")}
                        required={false}
                        formField={dataStore.returnAddressFormField}
                        errorMarkerOnly={true}
        />
        {addressTypeInfoIcon}
      </DHLFormGroup>
    </DHLFormGroup>
    {dataStore.returnAddressFormField.hasError &&
    <DHLFormGroup className={"row"}>
      <div className="col-12">
        <DHLFieldNote name={"returnAddressNotSelectedError"} type={"error"}
                      notes={dataStore.returnAddressFormField.error ?? ""} />
      </div>
    </DHLFormGroup>}
    {renderReturnAddressContent(dataStore.returnAddressFormField.value)}
  </DHLFormGroup>;
});