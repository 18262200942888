/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { DHLButton, DHLButtonGroup } from "@gkuis/gkp-base-widgets";
import { ResponseplusOrderStore } from "../../../../stores/ResponseplusOrderStore";

export type OrderDataActionBarProps = {
  dataStore: ResponseplusOrderStore
}

export const OrderDataActionBar = ({dataStore}: OrderDataActionBarProps) => {
  const resourceDataStore = dataStore.baseStore.resourceDataStore;

  return (
    <>
      <DHLButtonGroup name="page1ActionbarGroup">
        <DHLButton
          type="secondary"
          name="cancel"
          label={resourceDataStore.getButton("order.cancel")}
          onClick={() => dataStore.cancelOrder()} />
      </DHLButtonGroup>
      <DHLButton
        type="primary"
        name="next"
        disabled={dataStore.contractIdValid === false || !dataStore.profileSelectStore.selectedProfile}
        label={resourceDataStore.getButton("order.page1-orderData.next")}
        onClick={() => dataStore.moveToPage2()} />
    </>
  );
};