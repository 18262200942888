/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import {
  createMessageWithSpecialTags, DHLFormGroup, DHLModal,
  DHLTextOutput, DialogFormContent, ResourceDataStore
} from "@gkuis/gkp-base-widgets";
import classNames from "classnames";
import "./MatrixCodeDialog.scss";

export type MatrixCodeDialogProps = {
  show: boolean,
  onClickClose: () => void,
  resourceDataStore: ResourceDataStore
}

export const MatrixCodeDialog = ({
                                   show,
                                   onClickClose,
                                   resourceDataStore
                                 }: MatrixCodeDialogProps) => {

  const matrixCodeDialogContent =
      <div className={"matrixCodeDialog"}>
        <DialogFormContent>
          <DHLFormGroup className={"outerFormGroup"}>
            <DHLFormGroup className={"fullWidth"}>
              <div className={classNames("increaseFontSize")}>
                <DHLTextOutput
                    value={createMessageWithSpecialTags(resourceDataStore.getLabel("order.page1-orderData.matrixCodeDialogFullWidthText"))} />
              </div>
            </DHLFormGroup>
            <DHLFormGroup className={classNames("row increaseHeadlineMargin")}>
              <div className="col-6">
                <DHLFormGroup>
                  <h6>
                    {resourceDataStore.getLabel("order.page1-orderData.matrixCodeDialogLeftHeadline")}
                  </h6>
                  <DHLTextOutput value={createMessageWithSpecialTags(resourceDataStore.getLabel("order.page1-orderData.matrixCodeDialogLeftText"))} />
                </DHLFormGroup>
              </div>
              <div className="col-6">
                <DHLFormGroup>
                  <h6>
                    {resourceDataStore.getLabel("order.page1-orderData.matrixCodeDialogRightHeadline")}
                  </h6>
                  <DHLTextOutput
                      value={createMessageWithSpecialTags(resourceDataStore.getLabel("order.page1-orderData.matrixCodeDialogRightText"))} />
                </DHLFormGroup>
              </div>
            </DHLFormGroup>
          </DHLFormGroup>
        </DialogFormContent>
      </div>;

  return (
      <DHLModal
          noActionBar={true}
          name="MatrixCode Info"
          className={"matrixCodeInfoContainer"}
          show={show}
          title={resourceDataStore.getLabel("order.page1-orderData.matrixCodeDialogHeadline")}
          onClickClose={onClickClose}
          content={matrixCodeDialogContent} />
  );

};