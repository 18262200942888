/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { DHLFormGroup, DHLHeadline, DHLTextOutput } from "@gkuis/gkp-base-widgets";

export function createDefaultPanel(headline: string, entry: string) {
  return <DHLFormGroup>
    <DHLFormGroup>
      <DHLHeadline type={"h6"}>
        {headline}
      </DHLHeadline>
    </DHLFormGroup>
    <DHLFormGroup>
      <DHLTextOutput value={entry} />
    </DHLFormGroup>
  </DHLFormGroup>;
}