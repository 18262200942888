/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { createMessageWithSpecialTags, DHLAlert, DHLIcon, DHLTextOutput, ResourceDataStore } from "@gkuis/gkp-base-widgets";
import classNames from "classnames";
import { ReactNode } from "react";

export type ResponseplusOrderStateTupel = {
  orderState: ReactNode,
  nextSteps?: ReactNode,
  orderStateKey: string,
  nextStepsKey?: string
}

export const stateFilterEntries: Set<string> = new Set([
  "orderState.received",
  "orderState.approved",
  "orderState.canceled",
  "orderState.printApproved",
  "orderState.error"
]);

export const nextStepsFilterEntries: Set<string> = new Set([
  "nextStep.createPrintFile",
  "nextStep.printAndShipWholeEdition",
  "nextStep.printAndShipTest",
  "nextStep.correctPrintFile",
  "nextStep.correctTest"
]);

export const responseplusOrderStateMap: Map<number, ResponseplusOrderStateTupel> = new Map();

export const createResponseplusOrderStateMap = (resourceDataStore: ResourceDataStore): Map<number, ResponseplusOrderStateTupel> => {
  if (responseplusOrderStateMap.size > 0) {
    return responseplusOrderStateMap;
  }
  responseplusOrderStateMap.set(201, {
    orderState: getOrderStateReceived(201, resourceDataStore),
    nextSteps: getNextStepsCreatePrintFile(201, resourceDataStore),
    orderStateKey: getOrderStateReceivedKey(),
    nextStepsKey: getNextStepsCreatePrintFileKey()
  });
  responseplusOrderStateMap.set(202, {
    orderState: getOrderStateApproved(202, resourceDataStore),
    nextSteps: getNextStepsPrintAndShipWholeEdition(202, resourceDataStore),
    orderStateKey: getOrderStateApprovedKey(),
    nextStepsKey: getNextStepsPrintAndShipWholeEditionKey()
  });
  responseplusOrderStateMap.set(203, {
    orderState: getOrderStateCanceled(203, resourceDataStore),
    orderStateKey: getOrderStateCanceledKey()
  });
  responseplusOrderStateMap.set(300, {
    orderState: getOrderStateApproved(300, resourceDataStore),
    nextSteps: getNextStepsPrintAndShipWholeEdition(300, resourceDataStore),
    orderStateKey: getOrderStateApprovedKey(),
    nextStepsKey: getNextStepsPrintAndShipWholeEditionKey()
  });
  responseplusOrderStateMap.set(301, {
    orderState: getOrderStatePrintApproved(301, resourceDataStore),
    nextSteps: getNextStepsPrintAndShipTest(301, resourceDataStore),
    orderStateKey: getOrderStatePrintApprovedKey(),
    nextStepsKey: getNextStepsPrintAndShipTestKey()
  });
  responseplusOrderStateMap.set(302, {
    orderState: getOrderStateError(302, resourceDataStore),
    nextSteps: getNextStepsCorrectPrintFile(302, resourceDataStore),
    orderStateKey: getOrderStateErrorKey(),
    nextStepsKey: getNextStepsCorrectPrintFileKey()
  });
  responseplusOrderStateMap.set(303, {
    orderState: getOrderStateApproved(303, resourceDataStore),
    nextSteps: getNextStepsPrintAndShipWholeEdition(303, resourceDataStore),
    orderStateKey: getOrderStateApprovedKey(),
    nextStepsKey: getNextStepsPrintAndShipWholeEditionKey()
  });
  responseplusOrderStateMap.set(304, {
    orderState: getOrderStateError(304, resourceDataStore),
    nextSteps: getNextStepsCorrectTest(304, resourceDataStore),
    orderStateKey: getOrderStateErrorKey(),
    nextStepsKey: getNextStepsCorrectTestKey()
  });
  return responseplusOrderStateMap;
};

const getOrderStateReceived = (state: number, resourceDataStore: ResourceDataStore): ReactNode => {
  return <div className={classNames("row", "responseplus-overview-status")}>
    <DHLIcon name={state + "_state_received_icon"} icon={"angenommen"} />
    <DHLTextOutput name={state + "_state_received_text"}
                   value={createMessageWithSpecialTags(resourceDataStore.getLabel("orderState.received"))} />
  </div>;
};

const getOrderStateApproved = (state: number, resourceDataStore: ResourceDataStore): ReactNode => {
  return <div className={classNames("row", "responseplus-overview-status", "approved")}>
    <DHLIcon name={state + "_state_approved_icon"} icon={"ofi-form-checked"} />
    <DHLTextOutput name={state + "_state_approved_text"}
                   value={createMessageWithSpecialTags(resourceDataStore.getLabel("orderState.approved"))} />
  </div>;
};

const getOrderStateCanceled = (state: number, resourceDataStore: ResourceDataStore): ReactNode => {
  return <div className={classNames("row", "responseplus-overview-status", "canceled")}>
    <DHLIcon name={state + "_state_canceled_icon"} icon={"storniert"} />
    <DHLTextOutput name={state + "_state_canceled_text"}
                   value={createMessageWithSpecialTags(resourceDataStore.getLabel("orderState.canceled"))} />
  </div>;
};

const getOrderStatePrintApproved = (state: number, resourceDataStore: ResourceDataStore): ReactNode => {
  return <div className={classNames("row", "responseplus-overview-status", "printApproved")}>
    <DHLIcon name={state + "_state_printApproved_icon"} icon={"ofi-form-checked"} className={"order-print-approved"} />
    <DHLTextOutput name={state + "_state_printApproved_text"}
                   value={createMessageWithSpecialTags(resourceDataStore.getLabel("orderState.printApproved"))} />
  </div>;
};

const getOrderStateError = (state: number, resourceDataStore: ResourceDataStore): ReactNode => {
  return <div className={classNames("row", "responseplus-overview-status", "error")}>
    <DHLIcon name={state + "_state_error_icon"} icon={"hinweis-colored"} />
    <DHLTextOutput name={state + "_state_error_text"}
                   value={createMessageWithSpecialTags(resourceDataStore.getLabel("orderState.error"))} />
  </div>;
};

const getNextStepsCreatePrintFile = (state: number, resourceDataStore: ResourceDataStore): ReactNode => {
  return <DHLAlert name={state + "_nextSteps_createPrintFile"} label={resourceDataStore.getLabel("nextStep.createPrintFile")} type={"default"} />;
};

const getNextStepsPrintAndShipWholeEdition = (state: number, resourceDataStore: ResourceDataStore): ReactNode => {
  return <DHLAlert name={state + "_nextSteps_printAndShipWholeEdition"} label={resourceDataStore.getLabel("nextStep.printAndShipWholeEdition")}
                   type={"success"} />;
};

const getNextStepsPrintAndShipTest = (state: number, resourceDataStore: ResourceDataStore): ReactNode => {
  return <DHLAlert name={state + "_nextSteps_printAndShipTest"} label={resourceDataStore.getLabel("nextStep.printAndShipTest")}
                   type={"default"} />;
};

const getNextStepsCorrectPrintFile = (state: number, resourceDataStore: ResourceDataStore): ReactNode => {
  return <DHLAlert name={state + "_nextSteps_correctPrintFile"} label={resourceDataStore.getLabel("nextStep.correctPrintFile")}
                   type={"warning"} />;
};

const getNextStepsCorrectTest = (state: number, resourceDataStore: ResourceDataStore): ReactNode => {
  return <DHLAlert name={state + "_nextSteps_correctTest"} label={resourceDataStore.getLabel("nextStep.correctTest")} type={"warning"} />;
};


const getOrderStateReceivedKey = (): string => {
  return "orderState.received";
};

const getOrderStateApprovedKey = (): string => {
  return "orderState.approved";
};

const getOrderStateCanceledKey = (): string => {
  return "orderState.canceled";
};

const getOrderStatePrintApprovedKey = (): string => {
  return "orderState.printApproved";
};

const getOrderStateErrorKey = (): string => {
  return "orderState.error";
};

const getNextStepsCreatePrintFileKey = (): string => {
  return "nextStep.createPrintFile";
};

const getNextStepsPrintAndShipWholeEditionKey = (): string => {
  return "nextStep.printAndShipWholeEdition";
};

const getNextStepsPrintAndShipTestKey = (): string => {
  return "nextStep.printAndShipTest";
};

const getNextStepsCorrectPrintFileKey = (): string => {
  return "nextStep.correctPrintFile";
};

const getNextStepsCorrectTestKey = (): string => {
  return "nextStep.correctTest";
};

