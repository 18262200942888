/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { DHLButton, DHLButtonGroup } from "@gkuis/gkp-base-widgets";
import { ResponseplusOrderStore } from "../../../../stores/ResponseplusOrderStore";

export type OrderDetailsActionBarProps = {
  dataStore: ResponseplusOrderStore
}

export const OrderDetailsActionBar = ({dataStore}: OrderDetailsActionBarProps) => {
  const resourceDataStore = dataStore.baseStore.resourceDataStore;

  return (
    <>
      <DHLButtonGroup name="page2ActionbarGroup">
        <DHLButton
          type="default"
          name="back"
          label={resourceDataStore.getButton("order.page2-orderDetails.back")}
          onClick={() => dataStore.backToPage1()}
        />
        <DHLButton
          type="secondary"
          name="cancel"
          label={resourceDataStore.getButton("order.cancel")}
          onClick={() => {
            dataStore.cancelOrder();
          }}
        />
      </DHLButtonGroup>
      <DHLButton
        type="primary"
        name="next"
        label={resourceDataStore.getButton("order.page2-orderDetails.next")}
        onClick={() => dataStore.moveToPage3()}
      />
    </>
  );
};