/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { AccessToken, AuthenticationManager } from "@gkuis/gkp-authentication";
import { formatDate, FormField, logger, ResourceDataStore, ValidationRuleDataStore } from "@gkuis/gkp-base-widgets";
import { DHLSelectOneOptionType, ErrorResponse } from "@gkuis/gkp-base-widgets";
import { action, computed, flow, makeObservable, observable, reaction, runInAction } from "mobx";
import moment from "moment";
import { ResponseplusClient } from "../clients/ResponseplusClient";
import { ResponseplusConstants } from "../constants/ResponseplusConstants";
import { RppAddress } from "../types/RppAddress";
import { RppContact } from "../types/RppContact";
import { RppCustomerProfile } from "../types/RppCustomerProfile";
import { RppFrankingMark } from "../types/RppFrankingMark";
import { RppOrder } from "../types/RppOrder";
import { RppProduct } from "../types/RppProduct";
import { ResponseplusCustomerProfileSelectStore } from "./ResponseplusCustomerProfileSelectStore";
import { ResponseplusOrderBaseStore } from "./ResponseplusOrderBaseStore";

const LOG_MODULE = "ResponseplusOrderStore";

export class ResponseplusOrderStore {
  baseStore: ResponseplusOrderBaseStore;
  static COPIED = "copied";

  private readonly PAGE_1_INDEX = 0;
  private readonly PAGE_2_INDEX = 1;
  private readonly PAGE_3_INDEX = 2;

  activePageIndex: number = this.PAGE_1_INDEX;

  profileSelectStore: ResponseplusCustomerProfileSelectStore;

  nrFrankierartFormField: FormField<string>;
  selectedShipmentFormatFormField: FormField<string>;
  plannedShipmentQuantityFormField: FormField<string>;
  plannedStartDate: FormField<string>;
  plannedEndDate: FormField<string>;

  frankingZoneDesignFormField: FormField<string>;
  returnAddressFormField: FormField<string>;

  addressCompanyNameFormField: FormField<string>;
  addressAddition1FormField: FormField<string>;
  addressAddition2FormField: FormField<string>;
  addressAddition3FormField: FormField<string>;
  addressAddition4FormField: FormField<string>;
  addressAddition5FormField: FormField<string>;
  addressStreetFormField: FormField<string>;
  addressHouseNumberFormField: FormField<string>;
  addressPostcodeFormField: FormField<string>;
  addressCityFormField: FormField<string>;
  addressPoBoxFormField: FormField<string>;

  customerOrderNumberFormField: FormField<string>;
  actionTermFormField: FormField<string>;
  remarksFormField: FormField<string>;

  page1Fields: FormField<string>[];
  page2BaseFields: FormField<string>[];
  page2RegularFields: FormField<string>[];
  page2POBFields: FormField<string>[];
  page2FreeInputFields: FormField<string>[];


  products: RppProduct[] = [];
  customerOrderIdList: string[] | undefined;
  frankingMarkPreview?: RppFrankingMark;
  page1NextWasCalled: boolean = false;
  showCancelDialog: boolean = false;
  showMatrixCodeInfoDialog: boolean = false;
  showFrankingZoneInfoDialog: boolean = false;
  contractIdValid?: boolean;
  customerOrderIdListLoading: boolean;
  contractIdValidationInProgress: boolean;
  orderLoading: boolean;
  orderSubmitting: boolean;

  constructor(
      resourceDataStore: ResourceDataStore,
      validationRuleDataStore: ValidationRuleDataStore,
      responseplusClient: ResponseplusClient,
      authenticationManager: AuthenticationManager,
      private readonly resetOverview: () => void,
      private readonly navigateToOverview: () => void,
      private readonly successMessagesToOverview: (headline: string, messages: string[]) => void
  ) {
    this.baseStore = new ResponseplusOrderBaseStore(resourceDataStore, validationRuleDataStore, responseplusClient, authenticationManager);
    this.profileSelectStore = new ResponseplusCustomerProfileSelectStore(this.baseStore, responseplusClient, authenticationManager);

    this.nrFrankierartFormField = new FormField("nrFrankierArtFormField", "",
        resourceDataStore, validationRuleDataStore, undefined, "nrFrankierart");
    this.selectedShipmentFormatFormField = new FormField("", "",
        resourceDataStore, validationRuleDataStore, undefined, "selectedShipmentFormat");
    this.plannedShipmentQuantityFormField = new FormField("order.page1-orderData.plannedShipmentQuantityPlaceholder", "",
        resourceDataStore, validationRuleDataStore, undefined, "amount");
    this.frankingZoneDesignFormField = new FormField("frankingZoneDesignFormField", "",
        resourceDataStore, validationRuleDataStore, undefined, "frankingZone");
    this.plannedStartDate = new FormField("plannedStartDateFormField",
        formatDate(moment(), "DD.MM.YYYY"),
        resourceDataStore, validationRuleDataStore, "order.page1-orderData.startDatePlaceHolder", "plannedStartDate");
    this.plannedEndDate = new FormField("plannedEndDateFormField",
        formatDate(moment().add(1, "day"), "DD.MM.YYYY"),
        resourceDataStore, validationRuleDataStore, "order.page1-orderData.endDatePlaceHolder", "plannedEndDate");

    this.returnAddressFormField = new FormField("returnAddressFormField", ResponseplusConstants.addressType.regular.toString(),
        resourceDataStore, validationRuleDataStore, undefined, "addressType");

    this.addressCompanyNameFormField = new FormField("order.page2-orderDetails.addressCompanyNamePlaceholder", "",
        resourceDataStore, validationRuleDataStore, undefined, "companyName");
    this.addressAddition1FormField = new FormField("order.page2-orderDetails.addressAddition1Placeholder", "",
        resourceDataStore, validationRuleDataStore, undefined, "addition");
    this.addressAddition2FormField = new FormField("order.page2-orderDetails.addressAddition2Placeholder", "",
        resourceDataStore, validationRuleDataStore, undefined, "addition");
    this.addressAddition3FormField = new FormField("order.page2-orderDetails.addressAddition3Placeholder", "",
        resourceDataStore, validationRuleDataStore, undefined, "addition");
    this.addressAddition4FormField = new FormField("order.page2-orderDetails.addressAddition4Placeholder", "",
        resourceDataStore, validationRuleDataStore, undefined, "addition");
    this.addressAddition5FormField = new FormField("order.page2-orderDetails.addressAddition5Placeholder", "",
        resourceDataStore, validationRuleDataStore, undefined, "addition");
    this.addressStreetFormField = new FormField("order.page2-orderDetails.addressStreetPlaceholder", "",
        resourceDataStore, validationRuleDataStore, undefined, "street");
    this.addressHouseNumberFormField = new FormField("order.page2-orderDetails.addressHouseNumber", "",
        resourceDataStore, validationRuleDataStore, undefined, "houseNumber");
    this.addressPostcodeFormField = new FormField("order.page2-orderDetails.addressPostcodePlaceholder", "",
        resourceDataStore, validationRuleDataStore, undefined, "postCode");
    this.addressCityFormField = new FormField("order.page2-orderDetails.addressCityPlaceholder", "",
        resourceDataStore, validationRuleDataStore, undefined, "city");
    this.addressPoBoxFormField = new FormField("order.page2-orderDetails.addressPoBoxPlaceholder", "",
        resourceDataStore, validationRuleDataStore, undefined, "pOBox");

    this.customerOrderNumberFormField = new FormField("order.page2-orderDetails.customerOrderNoPlaceHolder", "",
        resourceDataStore, validationRuleDataStore, undefined, "customerOrderNumber");
    this.actionTermFormField = new FormField("order.page2-orderDetails.actionTermPlaceHolder", "",
        resourceDataStore, validationRuleDataStore, undefined, "actionTerm");

    this.remarksFormField = new FormField("order.page2-orderDetails.remarksPlaceHolder", "",
        resourceDataStore, validationRuleDataStore, undefined, "orderNotes");

    // ensures that the input textarea has no additional label / text in front of it
    this.remarksFormField.labelKey = "";

    this.page1Fields = [
      this.selectedShipmentFormatFormField,
      this.plannedShipmentQuantityFormField,
      this.plannedStartDate,
      this.plannedEndDate,
      this.frankingZoneDesignFormField,
      this.nrFrankierartFormField
    ];

    this.page2BaseFields = this.getPage2BaseFields();

    this.page2FreeInputFields = [
      this.addressAddition3FormField,
      this.addressAddition4FormField,
      this.addressAddition5FormField,
    ];
    this.page2POBFields = [
      this.addressPoBoxFormField
    ];
    this.page2RegularFields = [
      this.addressStreetFormField,
      this.addressHouseNumberFormField
    ];


    this.baseStore.inputFormFields = this.page1Fields
        .concat(this.page2BaseFields)
        .concat(this.page2FreeInputFields)
        .concat(this.page2POBFields)
        .concat(this.page2RegularFields);
    this.baseStore.secondContactIsActive = false;
    this.customerOrderIdListLoading = false;
    this.contractIdValidationInProgress = false;
    this.orderLoading = false;
    this.orderSubmitting = false;

    this.customerOrderIdList = undefined;
    this.frankingMarkPreview = undefined;
    this.contractIdValid = undefined;

    makeObservable(this, {
      activePageIndex: observable,
      products: observable,
      customerOrderIdList: observable,
      frankingMarkPreview: observable,
      page1NextWasCalled: observable,
      showCancelDialog: observable,
      showMatrixCodeInfoDialog: observable,
      showFrankingZoneInfoDialog: observable,
      contractIdValid: observable,
      customerOrderIdListLoading: observable,
      contractIdValidationInProgress: observable,
      orderLoading: observable,
      orderSubmitting: observable,
      isLoading: computed,
      customerProfile: computed,
      shipmentFormats: computed,
      selectedProductLabel: computed,
      selectedFrankingZoneDesign: computed,
      selectedMatrixCode: computed,
      noMatrixCodeIsSelectedErrorShouldBeShown: computed,
      noFrankingZoneIsSelectedErrorShouldBeShown: computed,
      loadData: action,
      loadContactPersonData: action,
      loadProducts: action,
      loadCustomerOrderIdList: action,
      loadFrankingMarkPreview: action,
      reset: action,
      checkContractId: action,
      isCustomerOrderIdUnique: action,
      cancelOrder: action,
      closeCancelOrder: action,
      confirmCancelOrder: action,
      openFrankingZoneDialog: action,
      closeFrankingZoneDialog: action,
      openMatrixCodeDialog: action,
      closeMatrixCodeDialog: action,
      moveToPage2: action,
      moveToPage3: action,
      backToPage1: action,
      backToPage2: action,
      submitOrder: action,
    });

    reaction(() => authenticationManager.authenticatedSubject, () => this.reset());
    reaction(() => this.nrFrankierartFormField.value, () => {
      if (this.selectedShipmentFormatFormField.value.includes(ResponseplusOrderStore.COPIED)) {
        this.selectedShipmentFormatFormField.updateValue(this.selectedShipmentFormatFormField.value.replace(ResponseplusOrderStore.COPIED, ""));
      } else {
        this.selectedShipmentFormatFormField.updateValue("");
      }
    });
  }

  getPage2BaseFields(): FormField<string>[] {
    this.page2BaseFields = [this.returnAddressFormField,
      this.addressCompanyNameFormField,
      this.addressAddition1FormField,
      this.addressAddition2FormField,
      this.addressPostcodeFormField,
      this.addressCityFormField,
      this.customerOrderNumberFormField,
      this.actionTermFormField,
      this.baseStore.nameFormField,
      this.baseStore.surNameFormField,
      this.baseStore.emailFormField,
      this.baseStore.phoneFormField,
      this.remarksFormField
    ];
    return this.page2BaseFields;
  }

  get isLoading(): boolean {
    return this.baseStore.resourceDataStore.isLoading;
  }

  get customerProfile(): RppCustomerProfile | null {
    return this.profileSelectStore.selectedProfile;
  }

  get shipmentFormats(): DHLSelectOneOptionType[] {
    return this.products
        .filter(product => product.frankingType.toString() === this.nrFrankierartFormField.value)
        .map(product => ({key: product.id.toString(), value: product.name, disabled: false}));
  }

  get selectedProductLabel(): string {
    if (this.selectedShipmentFormatFormField.value) {
      return this.getSelectedProduct().name;
    }
    return "";
  }

  get selectedFrankingZoneDesign(): string {
    if (this.frankingZoneDesignFormField.value) {
      return this.baseStore.getFrankingZoneDesign(this.frankingZoneDesignFormField.value);
    }
    return "";
  }

  get selectedMatrixCode(): string {
    if (this.nrFrankierartFormField.value) {
      switch (this.nrFrankierartFormField.value) {
        case ResponseplusConstants.nrFrankierArt.static.toString():
          return this.baseStore.resourceDataStore.getLabel("order.page1-orderData.matrixCodeStatic");
        case ResponseplusConstants.nrFrankierArt.individual.toString():
          return this.baseStore.resourceDataStore.getLabel("order.page1-orderData.matrixCodeIndividual");
        default:
          return "";
      }
    }
    return "";
  }

  get noMatrixCodeIsSelectedErrorShouldBeShown(): boolean {
    return (this.selectedMatrixCode === "" && this.page1NextWasCalled);
  }

  get noFrankingZoneIsSelectedErrorShouldBeShown(): boolean {
    return (this.selectedFrankingZoneDesign === "" && this.page1NextWasCalled);
  }

  async loadData(): Promise<void> {
    await this.loadProducts();
    await this.profileSelectStore.loadCustomerProfiles();
    runInAction(() => this.baseStore.loading = false);
  }

  loadContactPersonData = flow(function* loadContactPersonData(this: ResponseplusOrderStore) {
    if (this.baseStore.nameFormField.value !== ""
        || this.baseStore.surNameFormField.value !== ""
        || this.baseStore.emailFormField.value !== ""
        || this.baseStore.phoneFormField.value !== "") {
      return null;
    }
    return yield this.baseStore.authenticationManager.getAccessTokenParsed()
        .then((token: AccessToken | undefined) => {
          this.baseStore.nameFormField.updateValue(token!.given_name ? token!.given_name : "");
          this.baseStore.surNameFormField.updateValue(token!.family_name ? token!.family_name : "");
          this.baseStore.emailFormField.updateValue(token!.email ? token!.email : "");
          this.baseStore.phoneFormField.updateValue(token!.phone ? token!.phone : "");
        });
  });

  async loadProducts(): Promise<void> {
    if (this.products.length === 0) {
      logger.log(LOG_MODULE, "loading products");
      try {
        const products = await this.baseStore.responseplusClient.loadProductList();
        runInAction(() => this.products = products);
      } catch (error) {
        logger.log(LOG_MODULE, error);
        const errorMessage = this.baseStore.resourceDataStore.getError("generalError");
        this.baseStore.messageDataStore.addErrorMessage(errorMessage);
        runInAction(() => this.products = []);
        throw error;
      }
    }
  }

  loadCustomerOrderIdList = flow(function* (this: ResponseplusOrderStore) {
    logger.log(LOG_MODULE, "loading customer order ids list");
    try {
      this.customerOrderIdListLoading = true;
      const ekp = this.profileSelectStore.ekp;
      this.customerOrderIdList = yield this.baseStore.responseplusClient.loadCustomerOrderIdList(ekp);
    } catch (error) {
      logger.log(LOG_MODULE, "Failed to load customer order ids list");
      const errorMessage = this.baseStore.resourceDataStore.getError("generalError");
      this.baseStore.messageDataStore.addErrorMessage(errorMessage);
      throw error;
    } finally {
      this.customerOrderIdListLoading = false;
    }
  });

  async loadFrankingMarkPreview(): Promise<void> {
    const order = this.createOrder();
    try {
      this.frankingMarkPreview = undefined;
      this.frankingMarkPreview = await this.baseStore.responseplusClient.loadFrankingMarkPreview(order);
    } catch (error) {
      logger.log(LOG_MODULE, "Failed to load franking mark preview");
      this.baseStore.handleGlobalErrorResponse(error as ErrorResponse);
    }
  }

  reset(): void {
    this.baseStore.reset();
    this.activePageIndex = this.PAGE_1_INDEX;
    this.frankingMarkPreview = undefined;
    this.selectedShipmentFormatFormField.updateValue("");
    this.page1NextWasCalled = false;
    this.showCancelDialog = false;
    this.contractIdValid = undefined;
    this.showMatrixCodeInfoDialog = false;
    this.showFrankingZoneInfoDialog = false;
    this.returnAddressFormField.updateValue(ResponseplusConstants.addressType.regular.toString());
    this.plannedStartDate.updateValue(formatDate(moment(), "DD.MM.YYYY"));
    this.plannedEndDate.updateValue(formatDate(moment()
        .add(1, "day"), "DD.MM.YYYY"));
    this.customerOrderIdListLoading = false;
    this.contractIdValidationInProgress = false;
    this.orderSubmitting = false;
    this.orderLoading = false;
  }

  checkContractId = flow(function* checkContractId(this: ResponseplusOrderStore) {
    if (this.contractIdValid !== undefined) {
      return this.contractIdValid;
    }
    try {
      this.contractIdValidationInProgress = true;
      this.contractIdValid = yield this.baseStore.responseplusClient.checkContractID(this.profileSelectStore.ekp);
      if (!this.contractIdValid) {
        logger.log(LOG_MODULE, "ContractID is invalid -> Missing permission");
        this.handleContractError("noPermission");
      }
      return this.contractIdValid;
    } catch (err) {
      logger.log(LOG_MODULE, "Error occurred checking contract id: ", err);
      this.handleContractError("generalError");
      throw err;
    } finally {
      this.contractIdValidationInProgress = false;
    }
  });

  private handleContractError(errorLocalizationKey: string) {
    if (this.profileSelectStore.availableProfiles.length > 1) {
      const message = this.baseStore.resourceDataStore.getError(errorLocalizationKey + "WithChoice",
          [ResponseplusCustomerProfileSelectStore.getCustomerProfileSelectLabel(this.profileSelectStore.selectedProfile!)]);
      this.baseStore.messageDataStore.addErrorMessage(message);
      //We should not show formular, cause no valid profile is selected
      //But we can not reset, because even reset of selected profile causes async removing of error message
      this.activePageIndex = -1;
    } else {
      const message = this.baseStore.resourceDataStore.getError(errorLocalizationKey);
      this.baseStore.messageDataStore.addErrorMessage(message);
    }
  }

  isCustomerOrderIdUnique(): boolean {
    if (!this.customerOrderIdList || this.customerOrderIdList.length === 0) {
      return true;
    }
    const customerIdToCheck = this.customerOrderNumberFormField.value.toLowerCase();
    const ids = this.customerOrderIdList.map(id => id.toLowerCase());
    if (ids.includes(customerIdToCheck)) {
      this.customerOrderNumberFormField.error = this.baseStore.resourceDataStore.getError("duplicateCustomerOrderNumber");
      return false;
    }
    return true;
  }

  cancelOrder() {
    this.showCancelDialog = true;
  }

  closeCancelOrder() {
    this.showCancelDialog = false;
  }

  confirmCancelOrder() {
    this.reset();
    this.navigateToOverview();
  }

  openFrankingZoneDialog() {
    this.showFrankingZoneInfoDialog = true;
  }

  closeFrankingZoneDialog() {
    this.showFrankingZoneInfoDialog = false;
  }

  openMatrixCodeDialog() {
    this.showMatrixCodeInfoDialog = true;
  }

  closeMatrixCodeDialog() {
    this.showMatrixCodeInfoDialog = false;
  }

  async moveToPage2() {
    this.baseStore.messageDataStore.clearErrorMessages();
    this.page1NextWasCalled = true;
    this.page1Fields.forEach(field => field.validate());
    if (!moment(this.plannedStartDate.value, "DD.MM.YYYY").isBefore(moment(this.plannedEndDate.value, "DD.MM.YYYY"))) {
      this.plannedEndDate.error = this.baseStore.resourceDataStore.getError("dateInFuture");
    }
    if (this.baseStore.hasAnyError()) {
      this.baseStore.messageDataStore.addErrorMessage(this.baseStore.resourceDataStore.getError("genericGlobalErrorDueToLocalError"));
    } else {
      await this.checkContractId();
      if (this.contractIdValid) {
        this.activePageIndex = this.PAGE_2_INDEX;
      }
    }
  }

  moveToPage3() {
    this.baseStore.messageDataStore.clearErrorMessages();
    this.baseStore.inputFormFields.forEach(formField => formField.clearError());
    this.page2BaseFields.forEach(f => f.validate());
    this.baseStore.secondContactIsActive && this.baseStore.secondContactFields.forEach(f => f.validate());
    switch (this.returnAddressFormField.value) {
      case ResponseplusConstants.addressType.regular.toString():
        this.page2RegularFields.forEach(f => f.validate());
        const rule = this.baseStore.validationRuleDataStore.getRule("combinedStreetAndHouseNumber");
        if (rule?.maxLength !== undefined
            && this.addressStreetFormField.value.length + this.addressHouseNumberFormField.value.length > rule.maxLength) {
          this.addressStreetFormField.setError(this.baseStore.resourceDataStore.getMessage(rule.errorLocalizationName ?? ""));
        }
        break;
      case ResponseplusConstants.addressType.poBox.toString():
        this.page2POBFields.forEach(f => f.validate());
        break;
      case ResponseplusConstants.addressType.freeInput.toString():
        this.page2FreeInputFields.forEach(f => f.validate());
        break;
      case ResponseplusConstants.addressType.largeReceiver.toString():
        break;
      default:
        logger.error(LOG_MODULE, "unhandled value in switch: ", this.returnAddressFormField.value);
    }

    if (this.baseStore.hasAnyError() || !this.isCustomerOrderIdUnique() || this.secondContactHasError()) {
      this.baseStore.messageDataStore.addErrorMessage(this.baseStore.resourceDataStore.getError("genericGlobalErrorDueToLocalError"));
    } else {
      this.loadFrankingMarkPreview().catch(err => logger.log(LOG_MODULE, err));
      this.activePageIndex = this.PAGE_3_INDEX;
    }
    logger.log("aca " + this.baseStore.secondContactIsActive);
    this.page2BaseFields.forEach(f => logger.warn(f.error));
    this.page2RegularFields.forEach(f => logger.warn(f.error));
    this.page2POBFields.forEach(f => logger.warn(f.error));
    this.page2FreeInputFields.forEach(f => logger.warn(f.error));
  }

  private secondContactHasError() {
    return this.baseStore.secondContactIsActive ? this.baseStore.secondContactFields.filter(f => f.hasError).length > 0 : false;
  }

  backToPage1() {
    this.baseStore.messageDataStore.clearErrorMessages();
    this.baseStore.inputFormFields.forEach(formField => formField.clearError());
    this.baseStore.secondContactIsActive && this.baseStore.secondContactFields.forEach(f => f.clearError());
    this.activePageIndex = this.PAGE_1_INDEX;
  }

  backToPage2() {
    this.baseStore.messageDataStore.clearErrorMessages();
    this.activePageIndex = this.PAGE_2_INDEX;
  }

  submitOrder(): Promise<void> {
    this.orderSubmitting = true;
    const order = this.createOrder();
    return this.baseStore.responseplusClient.submitOrder(order)
        .then(() => {
          const headline = this.baseStore.resourceDataStore.getMessage("responseplus.order.page3-orderconfirm.submit.success.headline");
          const message1 = this.baseStore.resourceDataStore.getMessage("responseplus.order.page3-orderconfirm-submit.success.message1");
          const message2 = this.baseStore.resourceDataStore.getMessage("responseplus.order.page3-orderconfirm-submit.success.message2");
          const message3 = this.baseStore.resourceDataStore.getMessage("responseplus.order.page3-orderconfirm-submit.success.message3");
          this.resetOverview();
          this.successMessagesToOverview(headline, [message1, message2, message3]);
          this.navigateToOverview();
          this.reset();
        }).catch((error) => {
          logger.log(LOG_MODULE, "error on submitting order: ", error);
          this.baseStore.messageDataStore.clearTemporaryMessages();
          this.baseStore.handleGlobalErrorResponse(error);
          runInAction(() => this.orderSubmitting = false);
        });
  }

  private getSelectedProduct() {
    return this.products.filter(p => p.id.toString() === this.selectedShipmentFormatFormField.value)[0];
  }

  private getContacts() {
    const contacts: RppContact[] = [{
      firstName: this.baseStore.nameFormField.value,
      lastName: this.baseStore.surNameFormField.value,
      phone: this.baseStore.phoneFormField.value,
      email: this.baseStore.emailFormField.value
    }];
    if (this.baseStore.secondContactIsActive) {
      contacts.push({
        firstName: this.baseStore.secondNameFormField.value,
        lastName: this.baseStore.secondSureNameFormField.value,
        phone: this.baseStore.secondPhoneFormField.value,
        email: this.baseStore.secondEmailFormField.value
      });
    }
    return contacts;
  }

  private getReturnAddress(): RppAddress {
    switch (this.returnAddressFormField.value) {
      case ResponseplusConstants.addressType.regular.toString():
        return {
          addressType: ResponseplusConstants.addressType.regular,
          companyName: this.addressCompanyNameFormField.value,
          addition1: this.addressAddition1FormField.value,
          addition2: this.addressAddition2FormField.value,
          street: this.addressStreetFormField.value,
          houseNumber: this.addressHouseNumberFormField.value,
          postcode: this.addressPostcodeFormField.value,
          city: this.addressCityFormField.value
        };
      case ResponseplusConstants.addressType.poBox.toString():
        return {
          addressType: ResponseplusConstants.addressType.poBox,
          companyName: this.addressCompanyNameFormField.value,
          addition1: this.addressAddition1FormField.value,
          addition2: this.addressAddition2FormField.value,
          postcode: this.addressPostcodeFormField.value,
          city: this.addressCityFormField.value,
          pOBox: this.addressPoBoxFormField.value
        };
      case ResponseplusConstants.addressType.largeReceiver.toString():
        return {
          addressType: ResponseplusConstants.addressType.largeReceiver,
          companyName: this.addressCompanyNameFormField.value,
          addition1: this.addressAddition1FormField.value,
          addition2: this.addressAddition2FormField.value,
          postcode: this.addressPostcodeFormField.value,
          city: this.addressCityFormField.value
        };
      default:
        return {
          addressType: ResponseplusConstants.addressType.freeInput,
          companyName: this.addressCompanyNameFormField.value,
          addition1: this.addressAddition1FormField.value,
          addition2: this.addressAddition2FormField.value,
          addition3: this.addressAddition3FormField.value,
          addition4: this.addressAddition4FormField.value,
          addition5: this.addressAddition5FormField.value,
          postcode: this.addressPostcodeFormField.value,
          city: this.addressCityFormField.value
        };
    }
  }

  private createOrder(): RppOrder {
    const order: RppOrder = {
      ekp: this.profileSelectStore.ekp,
      amount: parseInt(this.plannedShipmentQuantityFormField.value),
      contacts: this.getContacts(),
      customerOrderNumber: this.customerOrderNumberFormField.value,
      frankingZoneType: parseInt(this.frankingZoneDesignFormField.value),
      notes: this.remarksFormField.value,
      plannedStartDate: this.formatPlannedDate(this.plannedStartDate.value),
      plannedEndDate: this.formatPlannedDate(this.plannedEndDate.value),
      productId: this.getSelectedProduct().id,
      returnAddress: this.getReturnAddress()
    };
    if (this.nrFrankierartFormField.value === ResponseplusConstants.nrFrankierArt.static.toString()) {
      order.orderRelatedCampaignName = this.actionTermFormField.value;
    }
    return order;
  }

  private formatPlannedDate(date: string): string {
    if (this.baseStore.authenticationManager.language === "en") {
      return moment(date, "DD/MM/YYYY").format("DD.MM.YYYY");
    }
    return date;
  }

  getHelpFromActivePageIndex(isMultipleEkp: boolean) {
    switch (this.activePageIndex) {
      case this.PAGE_1_INDEX:
        return isMultipleEkp ? "createOrderMultipleEkp" : "createOrderSingleEkp";
      case this.PAGE_2_INDEX:
        return "orderDetails";
      case this.PAGE_3_INDEX:
        return "orderConfirm";
      default:
        return "error";
    }
  }

  selectProfile() {
    const selectProfileInput = this.profileSelectStore.profileSelectFormfield.value;
    this.reset();
    this.profileSelectStore.profileSelectFormfield.updateValue(selectProfileInput);
    if (this.profileSelectStore.selectedProfile) {
      this.checkContractId()
          .then(success => success ? this.loadCustomerOrderIdList() : undefined)
          .catch(err => logger.warn(LOG_MODULE, err));
    }
  }
}
