/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { AuthenticationManager } from "@gkuis/gkp-authentication";
import { FormField, KeyValueType, logger, ResourceDataStore, ValidationRuleDataStore } from "@gkuis/gkp-base-widgets";
import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { FileFormat } from "../clients/FileFormat";
import { ResponseplusClient } from "../clients/ResponseplusClient";
import { RppCustomerProfile } from "../types/RppCustomerProfile";
import { RppFrankingMark } from "../types/RppFrankingMark";
import { RppOrderEntry } from "../types/RppOrderEntry";
import { RppProduct } from "../types/RppProduct";
import { ResponseplusOrderBaseStore } from "./ResponseplusOrderBaseStore";
import { ResponseplusOrderStore } from "./ResponseplusOrderStore";
import { RppContact } from "../types/RppContact";
import { RppOrder } from "../types/RppOrder";

const LOG_MODULE = "ResponseplusOrderDetailStore";

export class ResponseplusOrderDetailStore {
  baseStore: ResponseplusOrderBaseStore;

  customerProfile: RppCustomerProfile | undefined;

  order?: RppOrderEntry;

  returnAddressFormField: string = "";
  addressCompanyNameFormField: string = "";
  addressAddition1FormField: string = "";
  addressAddition2FormField: string = "";
  addressAddition3FormField: string = "";
  addressAddition4FormField: string = "";
  addressAddition5FormField: string = "";
  addressStreetFormField: string = "";
  addressHouseNumberFormField: string = "";
  addressPostcodeFormField: string = "";
  addressCityFormField: string = "";
  addressPoBoxFormField: string = "";
  nrFrankierartFormField: string = "";
  plannedShipmentQuantityFormField: string = "";
  plannedStartDate: string = "";
  plannedEndDate: string = "";
  frankingMarkPreview?: RppFrankingMark;
  customerOrderNumberFormField: string = "";
  actionTermFormField: string = "";
  remarksFormField: string = "";
  selectedProductLabel: string = "";
  selectedFrankingZoneDesign: string = "";
  frankingMarkFormatOptions: KeyValueType[];

  productId: number = -1;
  frankingZoneType: number = -1;

  frankingMarkFormat: FormField<string>;
  withReturnAddress: FormField<boolean>;
  editingSecondContact = false;
  showContactDialog = false;
  contactDialogType: "not selected" | "addContact" | "editContact" = "not selected";

  orderStore: ResponseplusOrderStore;

  constructor(
      resourceDataStore: ResourceDataStore,
      validationRuleDataStore: ValidationRuleDataStore,
      responseplusClient: ResponseplusClient,
      authenticationManager: AuthenticationManager,
      orderStore: ResponseplusOrderStore
  ) {
    this.baseStore = new ResponseplusOrderBaseStore(resourceDataStore, validationRuleDataStore, responseplusClient, authenticationManager);

    this.frankingMarkFormat = new FormField("format", "", this.baseStore.resourceDataStore as ResourceDataStore,
        this.baseStore.validationRuleDataStore as ValidationRuleDataStore, "");
    this.withReturnAddress = new FormField("return", true, this.baseStore.resourceDataStore as ResourceDataStore,
        this.baseStore.validationRuleDataStore as ValidationRuleDataStore, "");
    this.frankingMarkFormatOptions = [
      {key: "2", value: "PNG"},
      {key: "3", value: "PNG (transparent)"},
      {key: "4", value: "JPG"},
      {key: "1", value: "PDF"}
    ];
    this.orderStore = orderStore;

    this.customerProfile = undefined;

    makeObservable<ResponseplusOrderDetailStore, "getContacts">(this, {
      customerProfile: observable,
      frankingMarkFormat: observable,
      withReturnAddress: observable,
      editingSecondContact: observable,
      showContactDialog: observable,
      contactDialogType: observable,
      isLoading: computed,
      setSelectedOrder: action,
      downloadOrderConfirmation: action,
      downloadFrankingMark: action,
      updateContacts: action,
      getContacts: action,
      deleteFirstAndFillSecondContact: action,
      deleteSecondContact: action,
      reset: action,
      getCustomerProfile: observable,
      resetWithReturnAddress: action
    });
  }

  get isLoading(): boolean {
    return this.baseStore.resourceDataStore.isLoading || this.baseStore.loading;
  }

  async setSelectedOrder(entry: RppOrderEntry, customerProfile: RppCustomerProfile, products: RppProduct[] | undefined) {
    this.baseStore.loading = true;

    this.order = entry;
    this.selectedProductLabel = entry.productName;
    this.customerProfile = customerProfile;

    return this.loadOrder(entry, products);
  }

  private loadOrder(entry: RppOrderEntry, products: RppProduct[] | undefined) {
    return this.baseStore.responseplusClient.loadOrderById(this.customerProfile!.ekp, entry.globalOrderID).then(order => {
      this.returnAddressFormField = order.returnAddress.addressType.toString();
      this.addressCompanyNameFormField = order.returnAddress.companyName;
      this.addressAddition1FormField = order.returnAddress.addition1;
      this.addressAddition2FormField = order.returnAddress.addition2;
      this.addressAddition3FormField = order.returnAddress.addition3 ?? "";
      this.addressAddition4FormField = order.returnAddress.addition4 ?? "";
      this.addressAddition5FormField = order.returnAddress.addition5 ?? "";
      this.addressStreetFormField = order.returnAddress.street ?? "";
      this.addressHouseNumberFormField = order.returnAddress.houseNumber ?? "";
      this.addressPostcodeFormField = order.returnAddress.postcode;
      this.addressCityFormField = order.returnAddress.city;
      this.addressPoBoxFormField = order.returnAddress.pOBox ?? "";
      this.plannedStartDate = order.plannedStartDate;
      this.plannedEndDate = order.plannedEndDate;
      this.customerOrderNumberFormField = order.customerOrderNumber;
      this.plannedShipmentQuantityFormField = order.amount.toString();
      this.remarksFormField = order.notes;
      this.actionTermFormField = order.orderRelatedCampaignName ?? "";
      this.baseStore.nameFormField.value = order.contacts[0].firstName;
      this.baseStore.surNameFormField.value = order.contacts[0].lastName;
      this.baseStore.emailFormField.value = order.contacts[0].email;
      this.baseStore.phoneFormField.value = order.contacts[0].phone;
      if (order.contacts.length > 1) {
        this.baseStore.secondContactIsActive = true;
        this.baseStore.secondNameFormField.value = order.contacts[1].firstName;
        this.baseStore.secondSureNameFormField.value = order.contacts[1].lastName;
        this.baseStore.secondEmailFormField.value = order.contacts[1].email;
        this.baseStore.secondPhoneFormField.value = order.contacts[1].phone;
      } else {
        this.baseStore.secondContactIsActive = false;
      }
      if (products && products?.filter(p => p.id === order.productId).length > 0) {
        this.nrFrankierartFormField = products?.filter(p => p.id === order.productId)[0].frankingType.toString();
      } else {
        this.nrFrankierartFormField = "Fehler";
      }
      this.selectedFrankingZoneDesign = this.baseStore.getFrankingZoneDesign(order.frankingZoneType.toString());

      this.productId = order.productId;
      this.frankingZoneType = order.frankingZoneType;

      this.baseStore.loading = false;
    }).catch((error) => {
      logger.log(LOG_MODULE, "error on retrieving order: ", error);
      const errorMessage = this.baseStore.resourceDataStore.getError("generalError");
      this.baseStore.messageDataStore.addErrorMessage(errorMessage);
    });
  }

  async downloadOrderConfirmation() {
    if (!this.order) {
      return;
    }
    try {
      await this.baseStore.responseplusClient.downloadOrderConfirmation(this.customerProfile!.ekp, this.order.globalOrderID);
    } catch (err) {
      const errorMessage = this.baseStore.resourceDataStore.getError("generalError");
      this.baseStore.messageDataStore.addErrorMessage(errorMessage);
    }
  }

  getStatusFromOrder(statusCode: number) {
    switch (statusCode) {
      case 201:
      case 301:
        return "received";
      case 202:
      case 300:
      case 303:
        return "approved";
      case 302:
      case 304:
        return "error";
      case 203:
        return "cancelled";
      default:
        return "";
    }
  }

  getIconHeadlineFromOrder(statusCode: number) {
    switch (statusCode) {
      case 202:
      case 300:
      case 303:
        return this.baseStore.resourceDataStore.getLabel("orderDetails.statusIconText202u300u303");
      case 201:
      case 203:
      case 301:
      case 302:
      case 304:
        return this.baseStore.resourceDataStore.getLabel("orderDetails.statusIconText" + statusCode);
      default:
        return "Invalid status Code";
    }
  }

  getHeadlineFromOrder(statusCode: number) {
    switch (statusCode) {
      case 201:
      case 203:
      case 302:
        return this.baseStore.resourceDataStore.getLabel("orderDetails.statusHeadline" + statusCode);
      case 202:
      case 300:
      case 303:
        return this.baseStore.resourceDataStore.getLabel("orderDetails.statusHeadline202u300u303");
      case 301:
      case 304:
        return this.baseStore.resourceDataStore.getLabel("orderDetails.statusHeadline301u304");
      default:
        return "Invalid status Code";
    }
  }

  getItemsFromOrder(statusCode: number): string[] {
    switch (statusCode) {
      case 201:
      case 203:
      case 302:
        return [this.baseStore.resourceDataStore.getLabel("orderDetails.statusMsg1u" + statusCode),
          this.baseStore.resourceDataStore.getLabel("orderDetails.statusMsg2u" + statusCode)];
      case 202:
      case 300:
      case 303:
        return [this.baseStore.resourceDataStore.getLabel("orderDetails.statusMsg1u202u300u303"),
          this.baseStore.resourceDataStore.getLabel("orderDetails.statusMsg2u202u300u303")];
      case 301:
      case 304:
        return [this.baseStore.resourceDataStore.getLabel("orderDetails.statusMsg1u" + statusCode),
          this.baseStore.resourceDataStore.getLabel("orderDetails.statusMsg2u301u304"),
          this.baseStore.resourceDataStore.getLabel("orderDetails.statusMsg3u301u304")];
      default:
        return [];
    }
  }

  async downloadFrankingMark() {
    const fileFormat: FileFormat = ResponseplusOrderDetailStore.fileFormatFromString(this.frankingMarkFormat.value);
    if (!this.order) {
      return;
    }
    try {
      await this.baseStore.responseplusClient.downloadFrankingMark(
          this.customerProfile!.ekp, this.order.globalOrderID, fileFormat, this.withReturnAddress.value);
      this.frankingMarkFormat.value = "";
    } catch (err) {
      this.baseStore.messageDataStore.addErrorMessage(this.baseStore.resourceDataStore.getError("generalError"));
    }
  }

  private static fileFormatFromString(fileFormatStr: string): FileFormat {
    switch (fileFormatStr) {
      case "1":
        return FileFormat.PDF;
      case "2":
        return FileFormat.PNG;
      case "3":
        return FileFormat.PNG_A;
      case "4":
        return FileFormat.JPEG;
      default:
        return FileFormat.PNG;
    }
  }

  async updateContacts(mode?: string) {
    const successMsg: string = mode === "delete"
        ? this.baseStore.resourceDataStore.getMsg("orderDetails.contactDeleteSuccess")
        : this.baseStore.resourceDataStore.getMsg("orderDetails.contactChangeSuccess");
    this.baseStore.runningContactUpdate = true;
    this.baseStore.messageDataStore.clear();
    let isNewContact = false;
    if (this.editingSecondContact && !this.baseStore.secondContactIsActive) {
      this.baseStore.secondContactIsActive = true;
      isNewContact = true;
    }
    try {
      await this.baseStore.responseplusClient.updateContactPerson(this.customerProfile!.ekp, this.order!.globalOrderID, this.getContacts());
      this.baseStore.messageDataStore.addSuccessMessage(successMsg);
      runInAction(() => this.baseStore.secondContactIsActive = this.getContacts().length > 1);
    } catch (err) {
      this.baseStore.messageDataStore.addErrorMessage(this.baseStore.resourceDataStore.getMsg("orderDetails.contactChangeFail"));
      //if contact is new but failed -> set secondContactIsActive to false
      if (isNewContact) {
        runInAction(() => this.baseStore.secondContactIsActive = false);
        runInAction(() => this.baseStore.secondContactFields.forEach(f => f.value = ""));
      } else {
        // if any contact was edited but failed, reset contact via backend
        await this.baseStore.responseplusClient.loadOrderById(this.customerProfile!.ekp, this.order!.globalOrderID).then(order => {
          this.resetOrderViaBackend(order);
        });
      }
    }
    runInAction(() => this.showContactDialog = false); // always close -> show error/success in details view
    runInAction(() => this.baseStore.runningContactUpdate = false);
  }

  private resetOrderViaBackend(order: RppOrder) {
    this.baseStore.nameFormField.value = order.contacts[0].firstName;
    this.baseStore.surNameFormField.value = order.contacts[0].lastName;
    this.baseStore.phoneFormField.value = order.contacts[0].phone;
    this.baseStore.emailFormField.value = order.contacts[0].email;
    if (order.contacts.length > 1 && order.contacts[1].firstName !== "" && order.contacts[1].lastName !== "" && order.contacts[1].email !== "") {
      this.baseStore.secondNameFormField.value = order.contacts[1].firstName;
      this.baseStore.secondSureNameFormField.value = order.contacts[1].lastName;
      this.baseStore.secondPhoneFormField.value = order.contacts[1].phone;
      this.baseStore.secondEmailFormField.value = order.contacts[1].email;
      this.baseStore.secondContactIsActive = true;
    }
  }

  private getContacts() {
    const contact: RppContact = {
      firstName: ResponseplusOrderDetailStore.getValue(this.baseStore.nameFormField),
      lastName: ResponseplusOrderDetailStore.getValue(this.baseStore.surNameFormField),
      phone: ResponseplusOrderDetailStore.getValue(this.baseStore.phoneFormField),
      email: ResponseplusOrderDetailStore.getValue(this.baseStore.emailFormField)
    };
    const contacts = [contact];
    if (this.baseStore.secondContactFields.some(field => field.value !== "")) {
      const contact2: RppContact = {
        firstName: ResponseplusOrderDetailStore.getValue(this.baseStore.secondNameFormField),
        lastName: ResponseplusOrderDetailStore.getValue(this.baseStore.secondSureNameFormField),
        phone: ResponseplusOrderDetailStore.getValue(this.baseStore.secondPhoneFormField),
        email: ResponseplusOrderDetailStore.getValue(this.baseStore.secondEmailFormField)
      };
      contacts.push(contact2);
    }
    return contacts;
  }

  private static getValue(fieldOrString: FormField<string> | string): string {
    if (fieldOrString instanceof FormField) {
      return fieldOrString.value;
    } else {
      return fieldOrString;
    }
  }

  async deleteFirstAndFillSecondContact() {
    this.baseStore.deleteFirstContact();
    await this.updateContacts("delete");
  }

  async deleteSecondContact() {
    this.baseStore.resetSecondContact();
    await this.updateContacts("delete");
  }

  reset(): void {
    this.baseStore.reset();
    this.order = undefined;
    this.customerProfile = undefined;

    this.returnAddressFormField = "";
    this.addressCompanyNameFormField = "";
    this.addressAddition1FormField = "";
    this.addressAddition2FormField = "";
    this.addressAddition3FormField = "";
    this.addressAddition4FormField = "";
    this.addressAddition5FormField = "";
    this.addressStreetFormField = "";
    this.addressHouseNumberFormField = "";
    this.addressPostcodeFormField = "";
    this.addressCityFormField = "";
    this.addressPoBoxFormField = "";
    this.nrFrankierartFormField = "";
    this.plannedShipmentQuantityFormField = "";
    this.plannedStartDate = "";
    this.plannedEndDate = "";
    this.frankingMarkPreview = undefined;
    this.customerOrderNumberFormField = "";
    this.actionTermFormField = "";
    this.remarksFormField = "";
    this.selectedProductLabel = "";
    this.selectedFrankingZoneDesign = "";

    this.productId = -1;
    this.frankingZoneType = -1;

    this.frankingMarkFormat.updateValue("");
    this.withReturnAddress.updateValue(false);
    this.editingSecondContact = false;
    this.showContactDialog = false;
    this.baseStore.runningContactUpdate = false;
  }

  getCustomerProfile(): RppCustomerProfile | null {
    return this.customerProfile ?? null;
  }

  resetWithReturnAddress = (): void => {
    this.withReturnAddress.value = true;
  };

}
