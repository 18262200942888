/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */
import { FormField, logger, ResourceDataStore, ValidationRuleDataStore, DHLFilteredComboBoxDataType, ErrorResponse } from "@gkuis/gkp-base-widgets";
import { action, computed, makeObservable, observable, reaction, runInAction } from "mobx";
import { ResponseplusClient } from "../clients/ResponseplusClient";
import { RppCustomerProfile } from "../types/RppCustomerProfile";
import { ResponseplusOrderBaseStore } from "./ResponseplusOrderBaseStore";
import { ResponseplusOverviewStore } from "./ResponseplusOverviewStore";
import { AuthenticationManager } from "@gkuis/gkp-authentication";

const LOG_MODULE = "ResponseplusCustomerProfileSelectStore";

export class ResponseplusCustomerProfileSelectStore {
  customerProfiles: RppCustomerProfile[] = [];
  profileSelectFormfield: FormField<string>;
  profilesLoaded: boolean = false;

  constructor(
      readonly baseStore: ResponseplusOverviewStore | ResponseplusOrderBaseStore,
      readonly responseplusClient: ResponseplusClient,
      authenticationManagerOrTokenAuthHelper: AuthenticationManager,
  ) {
    this.profileSelectFormfield = new FormField("profileSelectFormfield", "",
        baseStore.resourceDataStore as ResourceDataStore, baseStore.validationRuleDataStore as ValidationRuleDataStore, "order.selectProfile");

    makeObservable(this, {
      customerProfiles: observable,
      profileSelectFormfield: observable,
      profilesLoaded: observable,
      availableProfiles: computed,
      selectedProfile: computed,
      ekp: computed,
      loadCustomerProfiles: action,
      reset: action
    });

    reaction(() => authenticationManagerOrTokenAuthHelper.authenticatedSubject, () => this.reset());
  }

  get availableProfiles(): DHLFilteredComboBoxDataType[] {
    return this.customerProfiles
        .slice()
        .sort((profileA: RppCustomerProfile, profileB: RppCustomerProfile) =>
            profileA.name1.localeCompare(profileB.name1))
        .map((profile) => (
            {value: ResponseplusCustomerProfileSelectStore.getCustomerProfileSelectLabel(profile)}));
  }

  get selectedProfile(): RppCustomerProfile | null {
    if (this.customerProfiles.length === 1) {
      return this.customerProfiles[0];
    }
    if (this.profileSelectFormfield.value === "") {
      return null;
    }
    const selectedCustomerProfile = this.customerProfiles.filter((cp) => {
      const cpLabel = ResponseplusCustomerProfileSelectStore.getCustomerProfileSelectLabel(cp);
      return cpLabel === this.profileSelectFormfield.value;
    });
    return selectedCustomerProfile.length === 1 ? selectedCustomerProfile[0] : null;
  }

  get ekp(): string {
    return this.selectedProfile?.ekp ?? "";
  }

  static getCustomerProfileSelectLabel(profile: RppCustomerProfile) {
    return profile.name1 + ", " + profile.ekp;
  }

  async loadCustomerProfiles(): Promise<void> {
    if (this.customerProfiles.length === 0) {
      logger.log(LOG_MODULE, "loading customer profiles");
      try {
        const customerProfiles = await this.responseplusClient.loadCustomerProfiles();
        runInAction(() => {
          this.customerProfiles = customerProfiles;
          this.profilesLoaded = true;
        });
        if (customerProfiles.length === 0) {
          const errorMessage = this.baseStore.resourceDataStore.getError("noProfile");
          if (this.baseStore.messageDataStore.getPermanentErrorMsgs().filter(m => m === errorMessage).length === 0) {
            runInAction(() => this.baseStore.messageDataStore.addPermanentErrorMessage(errorMessage));
          }
        }
        if (customerProfiles.length === 1) {
          runInAction(() => this.profileSelectFormfield.updateValue("0"));
        }
      } catch (error) {
        logger.log(LOG_MODULE, "Failed to load customer profiles");
        const errorResponse: ErrorResponse = error as ErrorResponse;
        this.baseStore.handleGlobalErrorResponse(errorResponse);
        throw error;
      }
    }
  }

  reset(): void {
    this.profileSelectFormfield.updateValue("");
  }

  copy(profileSelectStore: ResponseplusCustomerProfileSelectStore) {
    this.customerProfiles = profileSelectStore.customerProfiles;
    this.profileSelectFormfield.value = profileSelectStore.profileSelectFormfield.value;
  }
}