/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { FormField, ResourceDataStore } from "@gkuis/gkp-base-widgets";

export function getValue(fieldOrString: FormField<string> | string): string {
  if (fieldOrString instanceof FormField) {
    return fieldOrString.value;
  } else {
    return fieldOrString;
  }
}

export function getTextOrDefaultLabel(resourceDataStore: ResourceDataStore, label: string): string {
  return label || resourceDataStore.getLabel("order.page3-orderconfirm.emptyInput");
}

export function getOrderByCode(resourceDataStore: ResourceDataStore, value: string) {
  return resourceDataStore.getLabel("order.page3-orderconfirm." + value);
}