/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { DHLFormGroup, DHLHeadline, DHLLoading, DHLTextOutput, ResourceDataStore } from "@gkuis/gkp-base-widgets";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { ResponseplusConstants } from "../../../../constants/ResponseplusConstants";
import { ResponseplusOrderDetailStore } from "../../../../stores/ResponseplusOrderDetailStore";
import { ResponseplusOrderStore } from "../../../../stores/ResponseplusOrderStore";
import { RppFrankingMark } from "../../../../types/RppFrankingMark";
import { createDefaultPanel } from "../../../common/ResponseplusDefaultPanel";
import { ContactPersonDialog } from "../../ResponseplusOrderDetails/ContactPersonDialog";
import { getOrderByCode, getTextOrDefaultLabel, getValue } from "./values";
import { OrderContactPersonPanel } from "./OrderContactPersonPanel";
import { ResponseplusReturnAddressPanel } from "./ResponseplusReturnAddressPanel";

export type OrderConfirmContentProps = {
  orderStore: ResponseplusOrderStore | ResponseplusOrderDetailStore;
  showFrankingMarkPreview: boolean;
}

export const OrderContentPanel = observer(({orderStore, showFrankingMarkPreview}: OrderConfirmContentProps) => {
    const resourceDataStore: ResourceDataStore = orderStore.baseStore.resourceDataStore as ResourceDataStore;
    if (orderStore.isLoading) {
      return null;
    }

    function getFrankingMarkPreviewImageSrc(): string | undefined {
      const preview: RppFrankingMark | undefined = orderStore.frankingMarkPreview;
      if (!preview) {
        return undefined;
      }
      return `data:${preview.format};base64,${preview.contentBase64}`;
    }

    function renderFrankingPreview() {
      if (!frankingMarkPreview) {
        return (
          <div className={"responseplus-order-page3-container-franking-preview-loading"}>
            <div className={"responseplus-order-page3-container-franking-preview-loading-spinner"}>
              <DHLLoading
                name={"frankingMarkPreview-loading"}
                loading={true} />
            </div>
          </div>
        );
      }
      return (
        <img className={"responseplus-order-page3-container-franking-preview"}
             src={getFrankingMarkPreviewImageSrc()}
             alt={resourceDataStore.getLabel("order.page3-orderconfirm.frankingMarkPreview")} />
      );
    }

    const orderPanel = createDefaultPanel(resourceDataStore.getLabel(`order.page3-orderconfirm.order`),
      getOrderByCode(resourceDataStore, getValue(orderStore.nrFrankierartFormField)));

    const sendingAmountPanel = createDefaultPanel(resourceDataStore.getLabel("order.page3-orderconfirm.sendingAmount"),
      resourceDataStore.getLabel("order.page3-orderconfirm.sendingAmountPreamble") + getValue(orderStore.plannedShipmentQuantityFormField));
    const sendingPeriodPanel = createDefaultPanel(resourceDataStore.getLabel("order.page3-orderconfirm.plannedPeriod"),
      getValue(orderStore.plannedStartDate) + " - " + getValue(orderStore.plannedEndDate));
    const sendingFormatPanel =
      createDefaultPanel(resourceDataStore.getLabel("order.page3-orderconfirm.sendingFormat"), orderStore.selectedProductLabel);
    const customerOrderNumberPanel = createDefaultPanel(resourceDataStore.getLabel("order.page3-orderconfirm.customerOrderNumber"),
      getValue(orderStore.customerOrderNumberFormField));
    const notesPanel = createDefaultPanel(resourceDataStore.getLabel("order.page3-orderconfirm.notes"),
      getTextOrDefaultLabel(resourceDataStore, getValue(orderStore.remarksFormField)));
    const frankingMarkPreview = getFrankingMarkPreviewImageSrc();
    const frankingMarkPreviewPanel = (showFrankingMarkPreview &&
        <DHLFormGroup>
          <DHLFormGroup>
            <DHLHeadline type={"h6"}>
              {resourceDataStore.getLabel("order.page3-orderconfirm.frankingMarkPreview")}
            </DHLHeadline>
          </DHLFormGroup>
          <DHLFormGroup>
            {renderFrankingPreview()}
            <DHLTextOutput value={resourceDataStore.getLabel("order.page3-orderconfirm.frankingMarkPreview.hint")} />
          </DHLFormGroup>
        </DHLFormGroup>
    );
    const stampZoneDesignPanel = createDefaultPanel(
      resourceDataStore.getLabel("order.page3-orderconfirm.stampZoneDesign"),
      orderStore.selectedFrankingZoneDesign
    );
    const campaignNamePanel = getValue(orderStore.nrFrankierartFormField) === ResponseplusConstants.nrFrankierArt.static.toString()
      && createDefaultPanel(
        resourceDataStore.getLabel("order.page3-orderconfirm.campaignName"),
        getTextOrDefaultLabel(resourceDataStore, getValue(orderStore.actionTermFormField))
      );

    const contactPersonPanel = <OrderContactPersonPanel orderStore={orderStore}/>;

    const renderAddContactDialog = () => {
      const detailStore = (orderStore as ResponseplusOrderDetailStore);
      if (!detailStore.showContactDialog || !detailStore.order) {
        return null;
      }
      return (
        <ContactPersonDialog
          detailStore={orderStore}
          showContactDialog={(orderStore as ResponseplusOrderDetailStore).showContactDialog}
          editingSecondContact={(orderStore as ResponseplusOrderDetailStore).editingSecondContact}
          editingMode={(orderStore as ResponseplusOrderDetailStore).contactDialogType}
          onClickClose={() => (orderStore as ResponseplusOrderDetailStore).showContactDialog = false}
          onSubmit={() => (orderStore as ResponseplusOrderDetailStore).updateContacts()} />
      );
    };

    return (
      <>
        <DHLFormGroup className={classNames("row")}>
          <div className={classNames("col-6")}>
            {orderPanel}
            {<ResponseplusReturnAddressPanel orderStore={orderStore} />}
            {sendingAmountPanel}
            {sendingPeriodPanel}
            {sendingFormatPanel}
            {customerOrderNumberPanel}
            {notesPanel}
          </div>
          <div className={classNames("col-6")}>
            {frankingMarkPreviewPanel}
            {stampZoneDesignPanel}
            {campaignNamePanel}
            {contactPersonPanel}
          </div>
        </DHLFormGroup>
        {renderAddContactDialog()}
      </>
    );
  })
;