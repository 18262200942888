/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import {
  FetchAdapter,
  logger
} from "@gkuis/gkp-base-widgets";
import { ResponseplusConfiguration } from "../Responseplus";
import { RppCustomerProfile } from "../types/RppCustomerProfile";

const LOG_MODULE = "ResponseplusUserClient";

export class ResponseplusUserClient {

  private readonly contractIdUri: string;
  private readonly customerProfilesUri: string;

  constructor(config: ResponseplusConfiguration,
              private readonly fetchAdapter: FetchAdapter) {
    this.contractIdUri = config.contractIdUri;
    this.customerProfilesUri = config.customerProfilesUri;
  }

  async checkContractID(ekp: string): Promise<boolean> {
    logger.log(LOG_MODULE, "Loading contract ID");
    const uri = `${this.contractIdUri}/${ekp}`;
    const response = await this.fetchAdapter.get(uri);
    const statusCode = response.status;
    if (statusCode !== 200 && statusCode !== 404) {
      logger.log(LOG_MODULE, "Error checking contract ID");
      throw new Error("Error checking contract ID");
    }
    return response.status === 200;
  }

  async loadCustomerProfiles(): Promise<RppCustomerProfile[]> {
    logger.log(LOG_MODULE, "Loading customer profiles");
    const uri = this.customerProfilesUri;
    try {
      const address = await this.fetchAdapter.promisify<RppCustomerProfile[]>(await this.fetchAdapter.get(uri));
      logger.log(LOG_MODULE, "Loaded customer profiles");
      return address;
    } catch (err) {
      logger.log(LOG_MODULE, "Failed to load customer profiles");
      throw err;
    }
  }
}