/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */
import { AuthenticationManager } from "@gkuis/gkp-authentication";
import { ResourceDataStore, ValidationRuleDataStore } from "@gkuis/gkp-base-widgets";
import { action, makeObservable, observable } from "mobx";
import { ResponseplusClient } from "../clients/ResponseplusClient";
import { RppOrderCheckDocument } from "../types/RppOrderCheckDocument";
import { RppOrderEntry } from "../types/RppOrderEntry";
import { ResponseplusOrderDetailStore } from "./ResponseplusOrderDetailStore";

export class ResponseplusErrorStateStore {
  order?: RppOrderEntry;
  ekp?: string;

  loading = true;
  showErrorInfoDialog = false;
  errorInfoText: string | null = null;
  checkInfoIsLoading: boolean = true;
  errorInfoDownloads: RppOrderCheckDocument[] = [];

  detailsStore: ResponseplusOrderDetailStore;

  constructor(
      readonly resourceDataStore: ResourceDataStore,
      validationRuleDataStore: ValidationRuleDataStore,
      readonly responseplusClient: ResponseplusClient,
      authenticationManager: AuthenticationManager,
      detailsStore: ResponseplusOrderDetailStore
  ) {
    this.detailsStore = detailsStore;
    makeObservable(this, {
      loading: observable,
      showErrorInfoDialog: observable,
      errorInfoText: observable,
      checkInfoIsLoading: observable,
      errorInfoDownloads: observable,
      setSelectedOrder: action,
      loadOrderCheckInfo: action,
      downloadOrderCheckDocument: action,
      handleError: action,
      reset: action
    });
  }

  async setSelectedOrder(ekp: string, entry: RppOrderEntry) {
    this.loading = true;
    this.order = entry;
    this.ekp = ekp;
    await this.loadOrderCheckInfo();
  }

  async loadOrderCheckInfo() {
    if (!this.order) {
      return;
    }
    try {
      const response = await this.responseplusClient.loadOrderCheckInfo(this.ekp!, this.order.globalOrderID);
      this.errorInfoText = response.notes;
      this.errorInfoDownloads = response.documents;
      this.checkInfoIsLoading = false;
    } catch (err) {
      this.handleError();
    }
  }

  async downloadOrderCheckDocument(orderId: number, documentId: number) {
    try {
      await this.responseplusClient.downloadOrderCheckDocument(this.ekp!, orderId, documentId);
    } catch (err) {
      this.handleError();
    }
  }

  handleError = (): void => {
    this.detailsStore.baseStore.messageDataStore.addErrorMessage(this.resourceDataStore.getError("generalError"));
    this.showErrorInfoDialog = false;
    this.errorInfoText = "";
    this.errorInfoDownloads = [];
  };

  reset = (): void => {
    this.errorInfoText = null;
    this.errorInfoDownloads = [];
    this.checkInfoIsLoading = true;
  };
}