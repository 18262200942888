/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

export enum FileFormat {

  PNG = "PNG",
  PNG_A = "PNG-A",
  JPEG = "JPEG",
  TIFF = "TIFF",
  PDF ="PDF"

}