/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import {
  DHLButton,
  DHLFormGroup,
  DHLHeadline,
  DHLIcon,
  DHLTextInput,
  DHLTooltip,
  ResourceDataStore
} from "@gkuis/gkp-base-widgets";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { ResponseplusOrderStore } from "../../../../stores/ResponseplusOrderStore";
import "./OrderPage2ContentDetails.scss";

export type ResponseplusContactInputProps = {
  orderStore: ResponseplusOrderStore;
}

export const ResponseplusContactInput = observer((
    {
      orderStore: dataStore
    }: ResponseplusContactInputProps) => {

  const resourceDataStore: ResourceDataStore = dataStore.baseStore.resourceDataStore as ResourceDataStore;

  const [contactPersonTooltipOpen, setContactPersonTooltipOpen] = useState(false);
  const toggleContactPersonTooltip = () => setContactPersonTooltipOpen(!contactPersonTooltipOpen);


  function renderContactDeleteIcon(onClick: React.MouseEventHandler) {
    if (dataStore.baseStore.secondContactIsActive) {
      return <DHLIcon name={"deleteIcon"} icon={"delete"} onClick={onClick} className={"deleteIcon"} />;
    }
    return null;
  }

  function renderAddContact() {
    if (!dataStore.baseStore.secondContactIsActive) {
      return <DHLButton
          icon={"plus"}
          label={resourceDataStore.getButton("order.page2-orderDetails.addContact")}
          onClick={() => runInAction(() => dataStore.baseStore.secondContactIsActive = true)}
          type={"ghost"}
          size={"xs"}
          iconPosition={"icon-first"}
      />;
    }
    return null;
  }

  const contactPersonIcon =
      <>
        <DHLIcon name={"contactPersonInfoButton"} icon={"alert-info"} />
        <DHLTooltip target={"contactPersonInfoButton"} placement={"right"} tooltipOpen={contactPersonTooltipOpen} toggle={toggleContactPersonTooltip}>
          {resourceDataStore.getLabel("order.page2-orderDetails.contactPersonTooltip")}
        </DHLTooltip>
      </>;

  const contactPerson =
      <>
        <DHLFormGroup>
          <div className={"contactPersonWithDeleteIcon"}>
            <DHLHeadline type={"h6"} tooltip={contactPersonIcon}>
              {resourceDataStore.getLabel("order.page2-orderDetails.contactPerson")}
            </DHLHeadline>
            {renderContactDeleteIcon(() => dataStore.baseStore.deleteFirstContact())}
          </div>
        </DHLFormGroup>
        <DHLFormGroup className="row">
          <div className="col-6">
            <DHLTextInput formField={dataStore.baseStore.nameFormField} />
          </div>
          <div className="col-6">
            <DHLTextInput formField={dataStore.baseStore.surNameFormField} />
          </div>
        </DHLFormGroup>
        <DHLFormGroup>
          <DHLTextInput formField={dataStore.baseStore.emailFormField} />
        </DHLFormGroup>
        <DHLFormGroup>
          <DHLTextInput formField={dataStore.baseStore.phoneFormField} />
        </DHLFormGroup>
        {renderAddContact()}
      </>;

  const secondContactPerson =
      <>
        <DHLFormGroup>
          <div className={"contactPersonWithDeleteIcon"}>
            <DHLHeadline type={"h6"}>
              {resourceDataStore.getLabel("order.page2-orderDetails.secondContactPerson")}
            </DHLHeadline>
            {renderContactDeleteIcon(() => dataStore.baseStore.resetSecondContact())}
          </div>
        </DHLFormGroup>
        <DHLFormGroup className="row">
          <div className="col-6">
            <DHLTextInput formField={dataStore.baseStore.secondNameFormField} />
          </div>
          <div className="col-6">
            <DHLTextInput formField={dataStore.baseStore.secondSureNameFormField} />
          </div>
        </DHLFormGroup>
        <DHLFormGroup>
          <DHLTextInput formField={dataStore.baseStore.secondEmailFormField} />
        </DHLFormGroup>
        <DHLFormGroup>
          <DHLTextInput formField={dataStore.baseStore.secondPhoneFormField} />
        </DHLFormGroup>
      </>;

  if (!dataStore.baseStore.secondContactIsActive) {
    return <DHLFormGroup>
      {contactPerson}
    </DHLFormGroup>;
  } else  {
    return <>
      <DHLFormGroup>
        {contactPerson}
      </DHLFormGroup>
      <DHLFormGroup>
        {secondContactPerson}
      </DHLFormGroup>
    </>;
  }
});