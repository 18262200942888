/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import {
  createMessageWithSpecialTags,
  DHLFormGroup,
  DHLHeadline,
  DHLTextOutput,
  ResourceDataStore,
} from "@gkuis/gkp-base-widgets";
import { observer } from "mobx-react-lite";
import { ResponseplusOrderStore } from "../../../stores/ResponseplusOrderStore";

export type ResponseplusRightContentProps = {
  responseplusOrderStore: ResponseplusOrderStore;
}

export const ResponseplusRightContent = observer(({responseplusOrderStore: dataStore}: ResponseplusRightContentProps) => {
  const resourceDataStore: ResourceDataStore = dataStore.baseStore.resourceDataStore as ResourceDataStore;
  const isMultipleEkp: boolean = dataStore.profileSelectStore.availableProfiles.length > 1;

  let customerInfo = dataStore.profileSelectStore.selectedProfile?.name1
      + "[BR]";
  if (dataStore.profileSelectStore.selectedProfile?.name2) {
    customerInfo = customerInfo + dataStore.profileSelectStore.selectedProfile?.name2 + "[BR]";
  }
  if (dataStore.profileSelectStore.selectedProfile?.name3) {
    customerInfo = customerInfo + dataStore.profileSelectStore.selectedProfile?.name3 + "[BR]";
  }

  customerInfo = customerInfo
      + dataStore.profileSelectStore.selectedProfile?.street
      + " "
      + (dataStore.profileSelectStore.selectedProfile?.houseNumber ?? "")
      + "[BR]"
      + dataStore.profileSelectStore.selectedProfile?.postcode + " " + dataStore.profileSelectStore.selectedProfile?.city;

  const additionalCustomerInfo = <>
    <DHLFormGroup className="row">
      <DHLHeadline type={"h6"}>
        {resourceDataStore.getLabel("order.page1-orderData.ekp")}
      </DHLHeadline>
    </DHLFormGroup>
    <DHLFormGroup className="row">
      <DHLTextOutput value={dataStore.profileSelectStore.selectedProfile?.ekp} />
    </DHLFormGroup>
    <DHLFormGroup className="row">
      <DHLHeadline type={"h6"}>
        {resourceDataStore.getLabel("order.page1-orderData.customer")}
      </DHLHeadline>
    </DHLFormGroup>
    <DHLFormGroup className="row">
      <DHLTextOutput value={createMessageWithSpecialTags(customerInfo)} />
    </DHLFormGroup>
  </>;

  return <>
    {isMultipleEkp && additionalCustomerInfo}
    <DHLFormGroup className="row">
      <DHLHeadline type={"h6"}>
        {resourceDataStore.getLabel("order.page1-orderData.moreInfo")}
      </DHLHeadline>
    </DHLFormGroup>
    <DHLFormGroup className="row">
      <DHLTextOutput value={createMessageWithSpecialTags(resourceDataStore.getLabel("order.marginalInfoText1"))} />
    </DHLFormGroup>
    <DHLFormGroup className="row">
      <DHLTextOutput value={createMessageWithSpecialTags(resourceDataStore.getLabel("order.marginalInfoText2"))} />
    </DHLFormGroup>
  </>;
});