/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

export const FORMAT_NO_ORDER_NUMBER = -1;

export function formatOrderNumber(orderNumber: number): string {
  if (orderNumber === FORMAT_NO_ORDER_NUMBER) {
    return "";
  }
  const formatted = orderNumber.toString(16).toUpperCase();
  if (formatted.length === 1) {
    return `00${formatted}`;
  }
  if (formatted.length === 2) {
    return `0${formatted}`;
  }
  return formatted;
}