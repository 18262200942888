/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { DHLButtonLink, DHLIcon, DHLLoading, DHLModal, DialogFormContent } from "@gkuis/gkp-base-widgets";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { ResponseplusErrorStateStore } from "../../../stores/ResponseplusErrorStateStore";
import { RppOrderCheckDocument } from "../../../types/RppOrderCheckDocument";
import "./ErrorInfoDialog.scss";


export type ErrorInfoDialogProps = {
  errorStore: ResponseplusErrorStateStore;
  showErrorInfoDialog: boolean;
}

export const ErrorInfoDialog = observer(({
                                           errorStore,
                                           showErrorInfoDialog,
                                         }: ErrorInfoDialogProps) => {

  const resourceDataStore = errorStore.resourceDataStore;
  const downloadFiles: RppOrderCheckDocument[] = errorStore.errorInfoDownloads;
  const withDownloads: boolean = downloadFiles.length !== 0;

  const downloadFileToLink = (fileName: string, id: number) => {
    return (
        <div key={id} className={"row"}>
          <DHLIcon name={"download-" + id} icon={"download-file"} />
          <DHLButtonLink
              className={"buttonLink"}
              onClick={() => errorStore.downloadOrderCheckDocument(errorStore.order?.globalOrderID!, id)}
              label={fileName} />
        </div>
    );
  };

  const errorInfo = (className?: string) => {
    return (
        <DialogFormContent className={classNames(className, "dhlDialog-formContent", `${className}`)}>
          <div>
            {errorStore.errorInfoText}
          </div>
        </DialogFormContent>
    );
  };
  const downloads = (
      <DialogFormContent className={classNames("dhlDialog-formContent", "lower")}>
        <h6>{resourceDataStore.getLabel("orderDetails.downloadsHeader")}</h6>
        {downloadFiles.map(file => (downloadFileToLink(file.fileName, file.id)))}
      </DialogFormContent>
  );

  const dialogCompleteContent = () => {
    return (
        <>
          {errorInfo("upper")}
          {downloads}
        </>
    );
  };

  const getDialogContent = () => {
    return (
        <div>
          {withDownloads ?
              dialogCompleteContent() : errorInfo("upperOnly")}
        </div>
    );
  };
  const loadingView = () => {
    return (
            <div className={classNames( "dhlDialog-formContent", "spinner")}>
            <DHLLoading
                name={"errorInfoDialog-loading"}
                loading={true} />
          </div>);
  };

  return (
          <DHLModal
              name="errorInfo"
              className={"errorInfoDialog"}
              show={showErrorInfoDialog}
              title={resourceDataStore.getLabel("orderDetails.errorDialogHeader")}
              onClickClose={() => errorStore.showErrorInfoDialog = false}
              content={errorStore.checkInfoIsLoading ? loadingView() : getDialogContent()}
              noActionBar={true}
          />
  );
});