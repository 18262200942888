/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ResponseplusMain } from "../ResponseplusMain/ResponseplusMain";
import { reaction, runInAction } from "mobx";
import { AuthenticationManager } from "@gkuis/gkp-authentication";
import { Responseplus, ResponseplusConfiguration } from "../../../Responseplus";
import { AuthenticationManagerContext } from "@gkuis/gkp-base-widgets";
import moment from "moment";

export type ResponseplusRootProps = {
  authenticationManager: AuthenticationManager;
  responseplusConfiguration: ResponseplusConfiguration;
}

export const ResponseplusRoot = observer((
    {
      authenticationManager,
      responseplusConfiguration
    }: ResponseplusRootProps
) => {
  runInAction(() => {
    Responseplus.configure(responseplusConfiguration);
  });

  useEffect(() => reaction(
      () => authenticationManager.language,
      (language) => {
        moment.locale(language.startsWith("en") ? "en-gb" : "de");
      },
      {fireImmediately: true}
  ), [authenticationManager]);

  return (
      <AuthenticationManagerContext.Provider value={authenticationManager}>
        <BrowserRouter>
          <Routes>
            <Route path={`responseplus/*`} element={<ResponseplusMain returnUri="/" />} />
          </Routes>
        </BrowserRouter>
      </AuthenticationManagerContext.Provider>
  );
});