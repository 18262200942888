/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */
import { AuthenticationManager } from "@gkuis/gkp-authentication";
import {
  DataFilterStore,
  DataFilterStoreContext,
  dateFormatForLanguage,
  DHLButton,
  DHLDataFilter,
  DHLDataFilterSelectModule,
  DHLHelpButton,
  DHLMessages,
  DHLModal,
  DHLPagetemplate,
  DHLTable,
  DHLTableCell,
  DHLTableCellCustom,
  DHLTableCellDateTime,
  DHLTableCellIconButton,
  DHLTextInputFilter,
  DHLTextOutput,
  formatDate,
  logger,
  ResourceDataStore,
  SortOrder, useAuthenticationManager,
  ValidationRuleDataStore,
  trackPageCall,
  ErrorResponse,
  SortFunction
} from "@gkuis/gkp-base-widgets";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { ReactNode, useEffect } from "react";
import { ResponseplusOverviewStore } from "../../../index";
import { ResponseplusConstants } from "../../../constants/ResponseplusConstants";
import { ResponseplusUris } from "../../../types/ResponseplusUris";
import { RppOrderEntry, StateWithDate } from "../../../types/RppOrderEntry";
import { ProfileSelectBox } from "../../common/ProfileSelectBox";
import { formatOrderNumber } from "../../format";
import {
  createResponseplusOrderStateMap,
  nextStepsFilterEntries,
  responseplusOrderStateMap,
  stateFilterEntries
} from "./ResponseplusOrderStatusMapper";
import "./ResponseplusOverview.scss";
import { TFunction } from "i18next";

const LOG_MODULE = "ResponseplusOverview";

export type ResponseplusProps = {
  overviewStore: ResponseplusOverviewStore;
  uris: ResponseplusUris;
  handleRedirect: (uri: string) => void;
}

export const ResponseplusOverview = observer(({
                                                overviewStore,
                                                uris,
                                                handleRedirect
                                              }: ResponseplusProps) => {
  const authManager = useAuthenticationManager();
  const resourceDataStore: ResourceDataStore = overviewStore.resourceDataStore as ResourceDataStore;
  const validationRuleDataStore: ValidationRuleDataStore = overviewStore.validationRuleDataStore as ValidationRuleDataStore;
  const tableDataStore = overviewStore.tableDataStore;
  const dataFilterStore = overviewStore.dataFilterStore;
  const noResponseplusProfiles = overviewStore.profileSelectStore.profilesLoaded && overviewStore.profileSelectStore.customerProfiles.length === 0;

  useEffect(() => {
    resourceDataStore.loadLocalizations();
  }, [resourceDataStore]);

  useEffect(() => {
    validationRuleDataStore.load();
  }, [validationRuleDataStore]);

  useEffect(() => {
    if (!resourceDataStore.isLoading) {
      overviewStore.tableDataStore.switchToLoaded();
      overviewStore.profileSelectStore.loadCustomerProfiles()
        .catch(err => logger.log(LOG_MODULE, err));
    }
  }, [overviewStore, resourceDataStore.isLoading]);

  useEffect(() => {
    if (overviewStore.profileSelectStore.selectedProfile) {
      overviewStore.loadFilterEntries().catch(err => logger.log(LOG_MODULE, err));
      overviewStore.loadOrderEntries().catch(err => logger.log(LOG_MODULE, err));
      createResponseplusOrderStateMap(resourceDataStore);
    } else {
      const selectProfileInput = overviewStore.profileSelectStore.profileSelectFormfield.value;
      overviewStore.reset();
      overviewStore.profileSelectStore.profileSelectFormfield.updateValue(selectProfileInput);
      overviewStore.tableDataStore.switchToLoaded();
      overviewStore.loading = false;
    }
  }, [resourceDataStore, overviewStore, overviewStore.profileSelectStore.selectedProfile]);

  useEffect(() => {
    if (overviewStore.hasBufferedSuccessMessages) {
      overviewStore.deliverBufferedSuccessMessage();
    }
  }, [overviewStore, overviewStore.hasBufferedSuccessMessages]);

  useEffect(() => trackPageCall("Brief:Responseplus:Auftragsverwaltung"), []);

  if (overviewStore.isLoading) {
    return null;
  }

  const [textInputFilter, additionalFilters] = createFilters(resourceDataStore, validationRuleDataStore, overviewStore);

  const dataFilter =
    <DataFilterStoreContext.Provider value={dataFilterStore}>
      <DHLDataFilter<DataFilterStore<RppOrderEntry>>
        name={"overview.datafilter"}
        initialStateClosed={true}
        mainFilters={textInputFilter}
        additionalFilters={additionalFilters} />
    </DataFilterStoreContext.Provider>;

  const resetStores = () => {
    overviewStore.clearStoreMessages();
    overviewStore.errorStateStore.reset();
    overviewStore.detailStore.baseStore.messageDataStore.clear();
    overviewStore.detailStore.resetWithReturnAddress();
  };

  const showDetails = (mouseEvent: React.MouseEvent, entry: RppOrderEntry) => {
    overviewStore.setSelectedOrderForDetailView(entry);
    resetStores();
    handleRedirect(uris.responseplusOrderDetails);
  };

  const columns: JSX.Element[] = [
    <DHLTableCellDateTime
      key={"col-createdDate"}
      type="date"
      title={resourceDataStore.getLabel("colDate")}
      propertiesName="createdDate"
      sortable={true}
      dateFormat={dateFormatForLanguage(authManager.language)} />,
    <DHLTableCellCustom
      key={"col-orderNumber"}
      title={resourceDataStore.getLabel("colOrderNumber")}
      propertiesName="orderNumber"
      customRenderer={(orderNumber: number) => formatOrderNumber(orderNumber)}
      sortable={true} />,
    <DHLTableCell
      key={"col-customerOrderID"}
      title={resourceDataStore.getLabel("colCustomerOrderID")}
      propertiesName={"customerOrderID"}
      type={"text"}
      sortable={true} />,
    <DHLTableCell
      key={"col-productName"}
      title={resourceDataStore.getLabel("colProductName")}
      propertiesName={"productName"}
      type={"text"}
      sortable={true} />,
    <DHLTableCellCustom
      key={"col-state"}
      title={resourceDataStore.getLabel("colState")}
      propertiesName={"stateWithUpdateDate"}
      customRenderer={(stateWithDate: StateWithDate) => getStatusAndDateCell(authManager, stateWithDate)}
      sortable={true}
      comparator={compareByStateAndStateDate} />,
    <DHLTableCellCustom
      key={"col-nextSteps"}
      title={resourceDataStore.getLabel("colNextSteps")}
      propertiesName={"state"}
      customRenderer={(stateNumber: number) => responseplusOrderStateMap.get(stateNumber)?.nextSteps}
      sortable={true}
      comparator={compareBy("nextStepsKey")} />,
    <DHLTableCellIconButton
      key={"col-button-complained"}
      iconName={"storniert"}
      tooltip={resourceDataStore.getTooltip("table.complain")}
      onClick={(e: React.MouseEvent, rppOrderEntry: RppOrderEntry) => overviewStore.setRppOrderToCancel(rppOrderEntry)}
      isDisabled={(entry) => ResponseplusConstants.NON_CANCELABLE_STATES.includes((entry as RppOrderEntry).state)}
      propertiesName={""}
      disableMinWidth={true} />,
    <DHLTableCellIconButton
      key={"col-button-copy"}
      iconName={"copy"}
      tooltip={resourceDataStore.getTooltip("table.copy")}
      onClick={(mouseEvent: React.MouseEvent, entry: RppOrderEntry) => {
        overviewStore.setSelectedOrderForCopyView(entry);
        overviewStore.clearStoreMessages();
        handleRedirect(uris.responseplusOrder);
      }}
      propertiesName={""}
      disableMinWidth={true} />,
    <DHLTableCellIconButton
      key={"col-button-details"}
      iconName={"details"}
      tooltip={resourceDataStore.getTooltip("table.details")}
      onClick={showDetails}
      propertiesName={""}
      disableMinWidth={true} />
  ];

  const table =
    <DHLTable
      name="responseplusOverview"
      dataStore={tableDataStore}
      pager="bottom"
      idPropertiesName="orderNumber"
      noDataText={overviewStore.profileSelectStore.selectedProfile ? resourceDataStore.getMsg("emptyResponseplusOverview")
        : resourceDataStore.getMsg("noProfileSelected")}
      noDataDueToFilterText={resourceDataStore.getMsg("emptyResponseplusOverviewDueToFilter")}
      showDataStatusIndicator={true}
      children={columns}
      selected={showDetails} />;

  const messages =
    <DHLMessages
      msgStore={overviewStore.messageDataStore}
      name="messages" />;
  const newOrderButton =
    <DHLButton name="order"
               label={resourceDataStore.getButton("overview.add")}
               onClick={() => {
                 overviewStore.orderStore.profileSelectStore.copy(overviewStore.profileSelectStore);
                 handleRedirect(uris.responseplusOrder);
               }}
               type="default"
               size={"sm"} />;
  const helpButton = (
    <DHLHelpButton
      key={"reponseplusOverviewHelp"}
      url={resourceDataStore.getHelp("manageOrder")}
      tooltipText={resourceDataStore.getTooltip("helpButton")}
      type={"pagetemplateWithHeaderbutton"} />
  );
  const profileSelectBox = (
    <ProfileSelectBox
      profileSelectStore={overviewStore.profileSelectStore}
      resourceDataStore={resourceDataStore} />
  );

  const cancelConfirmDialog = createCancelConfirmDialog(resourceDataStore, overviewStore,);
  const pageContent = (
    <div>
      {dataFilter}
      {table}
      {cancelConfirmDialog}
    </div>
  );

  return (
    <DHLPagetemplate
      key={"responseplusOverview"}
      headerButtons={<>
        {newOrderButton}
        {helpButton}
      </>}
      topLeftItems={!noResponseplusProfiles && profileSelectBox}
      headlineText={resourceDataStore.getLabel("responseplus")}
      dhlMessage={messages}>
      {!noResponseplusProfiles && pageContent}
    </DHLPagetemplate>
  );
});

function createCancelConfirmDialog(
  resourceDataStore: ResourceDataStore,
  overviewStore: ResponseplusOverviewStore,
): JSX.Element {
  const onCancellationCanceled = () => overviewStore.setRppOrderToCancel(null);

  const onClickClose = () => {
    overviewStore.rppOrderToCancel = null;
    if (!overviewStore.orderCancelling) {
      overviewStore.setRppOrderToCancel(null);
    }
  };

  const onCancellationConfirmed = () => {
    overviewStore.cancelRppOrder()
      .then(() => logger.log("Selected rpp order canceled"))
      .catch((errors: ErrorResponse) => logger.log(`Selected rpp order cancellation failed with ${errors}`));
  };

  const cancelCancellationButton = (
    <DHLButton
      type="secondary"
      name="cancel"
      label={resourceDataStore.getButton("order.cancel")}
      onClick={onCancellationCanceled}
      disabled={overviewStore.orderCancelling} />
  );
  const confirmCancellationButton = (
    <DHLButton
      loadingState={overviewStore.orderCancelling}
      disabled={overviewStore.orderCancelling}
      type="primary"
      name="confirm"
      label={resourceDataStore.getButton("confirm.cancellation.yes")}
      onClick={onCancellationConfirmed} />
  );

  const actionBar = <>
    {cancelCancellationButton}
    {confirmCancellationButton}
  </>;

  return (
    <DHLModal
      name="rppOrder-cancel"
      show={overviewStore.rppOrderToCancel !== null}
      title={resourceDataStore.getMsg("confirm.cancellation.title")}
      message={resourceDataStore.getMsg("confirm.cancellation.confirmCancellationMsg")}
      onClickClose={onClickClose}
      actionbar={actionBar} />
  );
}

function createFilters(
  resourceDataStore: ResourceDataStore,
  validationRuleDataStore: ValidationRuleDataStore,
  overviewStore: ResponseplusOverviewStore
): JSX.Element[][] {
  const textInputFilter =
    <DHLTextInputFilter
      key={"overview.dataFilter.fullText"}
      idSymbol={"overview.dataFilter.fullText"}
      name={"overview.dataFilter.fullText"}
      resourceDataStore={resourceDataStore}
      validationRuleDataStore={validationRuleDataStore} />;

  const productFilter =
    <DHLDataFilterSelectModule
      key={"overview.dataFilter.products"}
      idSymbol={"overview.dataFilter.products"}
      label={resourceDataStore.getLabel("dataFilter.filter.products")}
      applyButtonLabel={resourceDataStore.getButton("dataFilter.confirm")}
      data={overviewStore.productFilterEntries}
      predicate={overviewStore.productFilterPredicate} />;

  const stateFilter =
    <DHLDataFilterSelectModule
      key={"overview.dataFilter.state"}
      idSymbol={"overview.dataFilter.state"}
      label={resourceDataStore.getLabel("dataFilter.filter.state")}
      applyButtonLabel={resourceDataStore.getButton("dataFilter.confirm")}
      data={stateFilterEntries}
      predicate={overviewStore.stateFilterPredicate}
      localize={(key: string) => resourceDataStore.getLabel(key)}
    />;

  const nextStepsFilter =
    <DHLDataFilterSelectModule
      key={"overview.dataFilter.nextSteps"}
      idSymbol={"overview.dataFilter.nextSteps"}
      label={resourceDataStore.getLabel("dataFilter.filter.nextSteps")}
      applyButtonLabel={resourceDataStore.getButton("dataFilter.confirm")}
      data={nextStepsFilterEntries}
      predicate={overviewStore.nextStepFilterPredicate}
      localize={(key: string) => resourceDataStore.getLabel(key)}
    />;
  return [[textInputFilter], [productFilter, stateFilter, nextStepsFilter]];
}

function getLabel(resourceDataStore: ResourceDataStore, entry: RppOrderEntry, propertyName: "orderStateKey" | "nextStepsKey"): string {
  const key = responseplusOrderStateMap.get(entry.state)?.[propertyName];
  return key ? resourceDataStore!.getLabel(key) : "";
}

function compareBy(propertyName: "orderStateKey" | "nextStepsKey") {
  return (resourceDataStore: ResourceDataStore | TFunction | null, _y: string, sortOrder: SortOrder) => (a: RppOrderEntry, b: RppOrderEntry) => {
    const label1 = getLabel(resourceDataStore as ResourceDataStore, a, propertyName);
    const label2 = getLabel(resourceDataStore as ResourceDataStore, b, propertyName);
    return sortOrder * label1.localeCompare(label2);
  };
}

function compareByStateAndStateDate(
  resourceDataStore: ResourceDataStore | TFunction | null,
  propertyName: string,
  sortOrder: SortOrder
): SortFunction<RppOrderEntry> {
  return (a: RppOrderEntry, b: RppOrderEntry) => {
    const compareStates = compareBy("orderStateKey")(resourceDataStore!, propertyName, sortOrder);
    const compareResult = compareStates(a, b);
    if (compareResult !== 0) {
      return compareResult;
    }
    const dateA = a.lastUpdatedDate as moment.Moment;
    const dateB = b.lastUpdatedDate as moment.Moment;
    if (dateA.isBefore(dateB)) {
      return -1 * sortOrder;
    }
    if (dateA.isAfter(dateB)) {
      return 1 * sortOrder;
    }
    return 0;
  };
}

function getStatusAndDateCell(authManager: AuthenticationManager, stateWithDate: StateWithDate): ReactNode {
  const status = responseplusOrderStateMap.get(stateWithDate.state)?.orderState as JSX.Element;
  const statusDate = formatDate(stateWithDate.lastUpdatedDate, dateFormatForLanguage(authManager.language));
  return (
    <div>
      {status}
      <DHLTextOutput
        name={stateWithDate.state + "_state_date"}
        className={classNames("row", "responseplus-overview-status", "stateDate")}
        value={statusDate} />
    </div>
  );
}