/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { ContextType, useContext } from "react";
//TODO not good for production! update with care! See https://github.com/remix-run/react-router/issues/8139
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";
import { History } from "history";

type NavigationContextWithHistory = ContextType<typeof NavigationContext> & {
  navigator: History;
};

export const useHistory = () => {
  const {navigator: history} = useContext(NavigationContext) as NavigationContextWithHistory;
  return history;
};