/*
 *  Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 *  This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { FetchAdapter, ResourceDataStore, useAuthenticationManager, useScopeCheck, ValidationRuleDataStore } from "@gkuis/gkp-base-widgets";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { ResponseplusClient } from "../../../clients/ResponseplusClient";
import { Responseplus } from "../../../Responseplus";
import { ResponseplusOverviewStore } from "../../../index";
import { ResponseplusUris } from "../../../types/ResponseplusUris";
import { ResponseplusOrder } from "../ResponseplusOrder/ResponseplusOrder";
import { ResponseplusOverview } from "../ResponseplusOverview/ResponseplusOverview";
import { ResponseplusOrderDetails } from "../ResponseplusOrderDetails/ResponseplusOrderDetails";
import { useHistory } from "../../common/util/useHistory";
import { Update } from "history";

export type ResponseplusMainProps = {
  returnUri: string;
};

export const ResponseplusMain = observer(({returnUri}: ResponseplusMainProps) => {
  const hasResponseplusScope = useScopeCheck("responseplus") ?? false;
  const authenticationManager = useAuthenticationManager();
  const [fetchAdapter] = useState(() => new FetchAdapter(authenticationManager));

  const moduleResponseplus = "responseplus";
  const config = Responseplus.configure();
  const [responseplusClient] = useState(new ResponseplusClient(config, fetchAdapter));
  const navigate = useNavigate();
  const history = useHistory();

  const responseplusUris: ResponseplusUris = {
    responseplusOverview: "overview",
    responseplusOrder: "order",
    responseplusOrderDetails: "details",
    return: returnUri
  };

  const handleRedirect = (uri: string): void => {
    navigate(uri);
  };

  const [responseplusStore] = useState(() => new ResponseplusOverviewStore(
      new ResourceDataStore(fetchAdapter, config.resourceDataBaseUri, "de", moduleResponseplus),
      new ValidationRuleDataStore(fetchAdapter, config.validationRulesBaseUri, moduleResponseplus),
      responseplusClient,
      authenticationManager,
      () => handleRedirect(responseplusUris.responseplusOverview)));
  const prevPath = useRef("");

  useEffect(() => {
    return history?.listen(({location}: Update) => {
      switch (prevPath.current) {
        case location.pathname:
          break;
        case responseplusUris.responseplusOrder:
          responseplusStore.orderStore.baseStore.messageDataStore.clear();
          break;
        case responseplusUris.responseplusOverview:
          responseplusStore.clearStoreMessages();
          break;
        case responseplusUris.responseplusOrderDetails:
          responseplusStore.detailStore.baseStore.messageDataStore.clear();
          break;
        default:
          break;
      }
      prevPath.current = location.pathname;
    });
  }, [
    responseplusStore.orderStore.baseStore.messageDataStore,
    responseplusStore.messageDataStore,
    responseplusStore.detailStore.baseStore.messageDataStore,
    history
  ]);

  return (
      <div data-testid="responseplus" className={classNames("responseplus-container")}>
        <Routes>
          <Route
              path={responseplusUris.responseplusOrder}
              element={hasResponseplusScope ? <ResponseplusOrder responseplusOrderStore={responseplusStore.orderStore}
                                                                 redirectBack={() => handleRedirect(responseplusUris.responseplusOverview)}
                  />
                  : null}
          />
          <Route
              path={responseplusUris.responseplusOrderDetails}
              element={hasResponseplusScope ? <ResponseplusOrderDetails responseplusStore={responseplusStore}
                                                                        uris={responseplusUris}
                                                                        handleRedirect={handleRedirect} />
                  : null}
          />
          <Route
              path={responseplusUris.responseplusOverview}
              element={hasResponseplusScope ? <ResponseplusOverview overviewStore={responseplusStore}
                                                                    uris={responseplusUris}
                                                                    handleRedirect={handleRedirect} />
                  : null}
          />
          <Route
              path={"*"}
              element={hasResponseplusScope ? <ResponseplusOverview overviewStore={responseplusStore}
                                                                    uris={responseplusUris}
                                                                    handleRedirect={handleRedirect} />
                  : null}
          />
        </Routes>
      </div>
  );
});
