/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { createMessageWithSpecialTags, DHLFormGroup, DHLIcon, DHLTextOutput, DHLButtonLink } from "@gkuis/gkp-base-widgets";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import "./RPOrderStatus.scss";
import { uid } from "react-uid";

export type RPOrderStatusProps = {
  statusType: string;
  iconHeadline: string;
  headline: string;
  bulletItems: string[];
  boldText?: string;
  statusCode: number;
  errorLinkText: string;
  onErrorInfoClick: () => void;
};

export const RPOrderStatus = observer(({
                                         statusType,
                                         statusCode,
                                         iconHeadline,
                                         headline,
                                         bulletItems,
                                         boldText,
                                         onErrorInfoClick,
                                         errorLinkText
                                       }: RPOrderStatusProps) => {

      function deriveIconFromStatus() {
        if (statusType.includes("received")) {
          return "angenommen";
        }
        if (statusType.includes("approved")) {
          return "ofi-form-checked";
        }
        if (statusType.includes("cancelled")) {
          return "storniert";
        }
        return "hinweis-colored";
      }

      const iconType: "angenommen" | "hinweis-colored" | "ofi-form-checked" | "storniert" = deriveIconFromStatus();
      // eslint-disable-next-line
      const bulletList = <ul data-testid={" "}>
        {(statusCode === 302 || statusCode === 304) &&
        <li key={0}><DHLButtonLink className={"buttonLink"} label={errorLinkText} onClick={onErrorInfoClick} /></li>}
        {bulletItems!.map(item => <li key={uid(item)}>{createMessageWithSpecialTags(item)}</li>)}
      </ul>;
      const bottomText = boldText &&
          <DHLFormGroup className={"col-12"}>
            <DHLTextOutput value={boldText} className={classNames("bold")} />
          </DHLFormGroup>;

      return (
          <div className={classNames("rporder-status-container", statusType)}>
            <DHLFormGroup className={"outer-form-group"}>
              <DHLFormGroup className={classNames("col-12 decreaseBottomMargin")}>
                <div className={"row"}>
                  <DHLIcon icon={iconType} name={"rporder-status-icon"} />
                  <DHLTextOutput value={iconHeadline} className={classNames("header-behind-icon")} />
                </div>
              </DHLFormGroup>
              <DHLFormGroup className={"col-12 decreaseBottomMargin"}>
                <h6>{headline}</h6>
              </DHLFormGroup>
              <DHLFormGroup className={"col-12"}>
                {bulletList}
              </DHLFormGroup>
              {bottomText}
            </DHLFormGroup>
          </div>
      );
    }
);
