/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { ResponseplusConfiguration } from "../Responseplus";
import { RppContact } from "../types/RppContact";
import { RppCustomerProfile } from "../types/RppCustomerProfile";
import { RppFrankingMark } from "../types/RppFrankingMark";
import { RppOrder } from "../types/RppOrder";
import { RppOrderEntry } from "../types/RppOrderEntry";
import { RppProduct } from "../types/RppProduct";
import { FileFormat } from "./FileFormat";
import { ResponseplusOrderClient } from "./ResponseplusOrderClient";
import { ResponseplusProductClient } from "./ResponseplusProductClient";
import { ResponseplusUserClient } from "./ResponseplusUserClient";
import { FetchAdapter } from "@gkuis/gkp-base-widgets";

export class ResponseplusClient {

  private readonly productClient: ResponseplusProductClient;
  private readonly orderClient: ResponseplusOrderClient;
  private readonly userClient: ResponseplusUserClient;

  constructor(configuration: ResponseplusConfiguration,
              private readonly fetchAdapter: FetchAdapter) {
    this.productClient = new ResponseplusProductClient(configuration, fetchAdapter);
    this.orderClient = new ResponseplusOrderClient(configuration, fetchAdapter);
    this.userClient = new ResponseplusUserClient(configuration, fetchAdapter);
  }

  cancelOrder(ekp: string, globalOrderID: number): Promise<void> {
    return this.orderClient.cancelOrder(ekp, globalOrderID);
  }

  checkContractID(ekp: string): Promise<boolean> {
    return this.userClient.checkContractID(ekp);
  }

  async downloadFrankingMark(ekp: string, orderID: number, fileFormat: FileFormat, withAddress: boolean): Promise<void> {
    return this.orderClient.downloadFrankingMark(ekp, orderID, fileFormat, withAddress);
  }

  async downloadOrderCheckDocument(ekp: string, orderId: number, documentId: number): Promise<void> {
    return this.orderClient.downloadOrderCheckDocument(ekp, orderId, documentId);
  }

  async downloadOrderConfirmation(ekp: string, globalOrderID: number): Promise<void> {
    return this.orderClient.downloadOrderConfirmation(ekp, globalOrderID);
  }

  loadCustomerOrderIdList(ekp: string): Promise<string[]> {
    return this.orderClient.loadCustomerOrderIdList(ekp);
  }

  loadCustomerProfiles(): Promise<RppCustomerProfile[]> {
    return this.userClient.loadCustomerProfiles();
  }

  loadFrankingMarkPreview(order: RppOrder): Promise<RppFrankingMark> {
    return this.orderClient.loadFrankingMarkPreview(order);
  }

  async loadOrderById(ekp: string, orderId: number) {
    return this.orderClient.loadOrderById(ekp, orderId);
  }

  async loadOrderCheckInfo(ekp: string, orderId: number) {
    return this.orderClient.loadOrderCheckInfo(ekp, orderId);
  }

  async loadOrderList(ekp: string): Promise<RppOrderEntry[]> {
    return this.orderClient.loadOrderList(ekp);
  }

  loadProductList(): Promise<RppProduct[]> {
    return this.productClient.loadProductList();
  }

  submitOrder(order: RppOrder): Promise<void> {
    return this.orderClient.submitOrder(order);
  }

  async updateContactPerson(ekp: string, orderId: number, contactPersonData: RppContact[]): Promise<void> {
    return this.orderClient.updateContactPerson(ekp, orderId, contactPersonData);
  }

}