/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */


import { AuthenticationManager } from "@gkuis/gkp-authentication";
import { BaseFormStore, FormField, logger, ResourceDataStore, ValidationRuleDataStore } from "@gkuis/gkp-base-widgets";
import { action, makeObservable, observable } from "mobx";
import { ResponseplusClient } from "../clients/ResponseplusClient";
import { ResponseplusConstants } from "../constants/ResponseplusConstants";

const LOG_MODULE = "ResponseplusOrderBaseStore";

export class ResponseplusOrderBaseStore extends BaseFormStore {
  nameFormField: FormField<string>;
  surNameFormField: FormField<string>;
  emailFormField: FormField<string>;
  phoneFormField: FormField<string>;
  secondNameFormField: FormField<string>;
  secondSureNameFormField: FormField<string>;
  secondEmailFormField: FormField<string>;
  secondPhoneFormField: FormField<string>;

  secondContactFields: FormField<string>[];
  secondContactIsActive: boolean;
  loading = true;
  runningContactUpdate: boolean = false;

  constructor(
      resourceDataStore: ResourceDataStore,
      validationRuleDataStore: ValidationRuleDataStore,
      readonly responseplusClient: ResponseplusClient,
      readonly authenticationManager: AuthenticationManager
  ) {
    super(resourceDataStore, validationRuleDataStore, null);

    this.nameFormField = new FormField("order.page2-orderDetails.namePlaceHolder", "",
        resourceDataStore, validationRuleDataStore, undefined, "firstName");
    this.surNameFormField = new FormField("order.page2-orderDetails.sureNamePlaceHolder", "",
        resourceDataStore, validationRuleDataStore, undefined, "lastName");
    this.emailFormField = new FormField("order.page2-orderDetails.emailPlaceHolder", "",
        resourceDataStore, validationRuleDataStore, undefined, "email");
    this.phoneFormField = new FormField("order.page2-orderDetails.phonePlaceHolder", "",
        resourceDataStore, validationRuleDataStore, undefined, "phone");
    this.secondNameFormField = new FormField("order.page2-orderDetails.namePlaceHolder", "",
        resourceDataStore, validationRuleDataStore, undefined, "firstName");
    this.secondSureNameFormField = new FormField("order.page2-orderDetails.sureNamePlaceHolder", "",
        resourceDataStore, validationRuleDataStore, undefined, "lastName");
    this.secondEmailFormField = new FormField("order.page2-orderDetails.emailPlaceHolder", "",
        resourceDataStore, validationRuleDataStore, undefined, "email");
    this.secondPhoneFormField = new FormField("order.page2-orderDetails.phonePlaceHolder", "",
        resourceDataStore, validationRuleDataStore, undefined, "phone");
    this.secondContactIsActive = false;
    this.secondContactFields = [
      this.secondNameFormField,
      this.secondSureNameFormField,
      this.secondEmailFormField,
      this.secondPhoneFormField,
    ];

    makeObservable(this, {
      secondContactFields: observable,
      secondContactIsActive: observable,
      loading: observable,
      runningContactUpdate: observable,
      reset: action,
      deleteFirstContact: action,
      resetSecondContact: action
    });
  }

  reset(): void {
    logger.log(LOG_MODULE, "Reset");
    this.resetSecondContact();
    this.resetInputFields();

    this.loading = true;

    this.messageDataStore.clear();
    this.clearInputFieldErrors();
    this.clearAllLocalFieldErrors();
  }

  getFrankingZoneDesign(s: string): string {
    switch (s) {
      case ResponseplusConstants.frankingZoneDesign.customerImageWithWave.toString():
        return this.resourceDataStore.getLabel("order.page1-orderData.customerImageWithWave");
      case ResponseplusConstants.frankingZoneDesign.emptyZone.toString():
        return this.resourceDataStore.getLabel("order.page1-orderData.emptyZone");
      case ResponseplusConstants.frankingZoneDesign.rectangleNoteWithMatrix.toString():
        return this.resourceDataStore.getLabel("order.page1-orderData.rectangleNoteWithMatrix");
      case ResponseplusConstants.frankingZoneDesign.rectangleNoteWithText.toString():
        return this.resourceDataStore.getLabel("order.page1-orderData.rectangleNoteWithText");
      default:
        return "";
    }
  }

  deleteFirstContact() {
    if (this.secondContactIsActive) {
      this.copySecondContactToFirst();
      this.resetSecondContact();
    }
  }

  private copySecondContactToFirst() {
    this.nameFormField.value = this.secondNameFormField.value;
    this.surNameFormField.value = this.secondSureNameFormField.value;
    this.emailFormField.value = this.secondEmailFormField.value;
    this.phoneFormField.value = this.secondPhoneFormField.value;
  }

  resetSecondContact() {
    this.secondContactIsActive = false;
    this.secondContactFields.forEach(f => f.updateValue(""));
    this.secondContactFields.forEach(f => f.clearError());
  }

  contactHasLocalFieldError(inputFields: FormField<string>[]) {
    inputFields.forEach(field => {
          field.validate();
        }
    );
    return inputFields.filter(field => field.hasError).length > 0;
  }
}