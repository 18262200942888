/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

export type ResponseplusConfiguration = {
  resourceDataBaseUri: string,
  validationRulesBaseUri: string
  productsUri: string
  frankingPreviewUri: string
  orderUri: string
  contractIdUri: string
  customerProfilesUri: string
  imageUri: string
}

export class Responseplus {
  private static config: ResponseplusConfiguration = {
    resourceDataBaseUri: "",
    validationRulesBaseUri: "",
    productsUri: "",
    frankingPreviewUri: "",
    orderUri: "",
    contractIdUri: "",
    customerProfilesUri: "",
    imageUri: ""
  };

  static configure(config?: Partial<ResponseplusConfiguration>): ResponseplusConfiguration {
    if (!config) {
      return Responseplus.config;
    }

    Responseplus.config = {...Responseplus.config, ...config};
    return Responseplus.config;
  }
}