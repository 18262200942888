/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { FetchAdapter, logger } from "@gkuis/gkp-base-widgets";
import { ResponseplusConfiguration } from "../Responseplus";
import { RppProduct } from "../types/RppProduct";

const LOG_MODULE = "ResponseplusClient";

export class ResponseplusProductClient {

  private readonly productUri: string

  constructor(config: ResponseplusConfiguration,
              private readonly fetchAdapter: FetchAdapter) {
    this.productUri = config.productsUri;
  }

  async loadProductList(): Promise<RppProduct[]> {
    logger.log(LOG_MODULE, "Loading products");
    const uri = `${this.productUri}`;
    try {
      return await this.fetchAdapter.promisify<RppProduct[]>(
        await this.fetchAdapter.get(uri)
      );
    } catch(err) {
      logger.log(LOG_MODULE, "Failed to load products");
      throw err;
    }
  }

}