/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */
export class ResponseplusConstants {
  public static nrFrankierArt = {
    individual: 1,
    static: 2,
  };

  public static frankingZoneDesign = {
    rectangleNoteWithText: 1,
    customerImageWithWave: 2,
    emptyZone: 3,
    rectangleNoteWithMatrix: 4,
  };

  public static addressType = {
    regular: 1,
    poBox: 2,
    largeReceiver: 3,
    freeInput: 4,
  };

  public static NON_CANCELABLE_STATES = [202, 300, 303, 203];
}