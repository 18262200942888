/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import {
  createMessageWithSpecialTags,
  DHLFormGroup,
  DHLImage,
  DHLModal,
  DHLTextOutput,
  DialogFormContent7030,
  ResourceDataStore
} from "@gkuis/gkp-base-widgets";
import classNames from "classnames";
import { Responseplus } from "../../../../../Responseplus";
import "./FrankingZoneDialog.scss"

export type FrankingZoneDialogProps = {
  show: boolean,
  onClickClose: () => void,
  resourceDataStore: ResourceDataStore
}

export const FrankingZoneDialog = ({
                                     show,
                                     onClickClose,
                                     resourceDataStore
                                   }: FrankingZoneDialogProps) => {
  const imageUri = Responseplus.configure().imageUri;
  const createImageWithDescription = (imageName: string | undefined, labelKey: string) => {
    return (
      <>
          <div className={"dhlRPPImage"}>
            {imageName && <DHLImage src={`${imageUri}${imageName}`} />}
          </div>
          <div className={"dhlRPPImageSubline"}>
            <DHLTextOutput value={createMessageWithSpecialTags(resourceDataStore.getLabel(labelKey))} />
          </div>
      </>
    );
  };

  const leftContent70 =
      <DHLFormGroup>
        <h6>
          {resourceDataStore.getLabel("order.page1-orderData.frankingZoneDialogLeftHeadline")}
        </h6>
        <DHLFormGroup className={classNames("row", "imageContainer")}>
          <div className={"col-4"}>
            {createImageWithDescription("image-rechteckvermerk@1x.jpg", "order.page1-orderData.frankingZoneDialogLeftText1")}
          </div>
          <div className={"col-4"}>
            {createImageWithDescription("image-frankierwelle@1x.jpg", "order.page1-orderData.frankingZoneDialogLeftText2")}
          </div>
          <div className={"col-4"}>
            {createImageWithDescription(undefined, "order.page1-orderData.frankingZoneDialogLeftText3")}
          </div>
        </DHLFormGroup>
      </DHLFormGroup>

  const rightContent30 =
    <DHLFormGroup>
      <h6>
        {resourceDataStore.getLabel("order.page1-orderData.frankingZoneDialogRightHeadline")}
      </h6>
      {createImageWithDescription("image-matrixcode@1x.jpg", "order.page1-orderData.frankingZoneDialogRightText")}
    </DHLFormGroup>;

  const postamble =
    <DHLTextOutput
      value={createMessageWithSpecialTags(resourceDataStore.getLabel("order.page1-orderData.frankingZoneDialogFullWidthText"))} />;

  const frankingZoneDialogContent = (
    <div className={"frankingZoneDialog"}>
      <DialogFormContent7030
        leftContent70={leftContent70}
        rightMarginal30={rightContent30}
        postamble={postamble} />
    </div>
  );

  return (
    <DHLModal
      noActionBar={true}
      name="Frankingzone Info"
      show={show}
      title={resourceDataStore.getLabel("order.page1-orderData.frankingZoneDialogHeadline")}
      onClickClose={onClickClose}
      content={frankingZoneDialogContent} />
  );
};