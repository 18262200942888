/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { observer } from "mobx-react-lite";
import { DHLHeadline, DHLLoading, DialogFormContent, LocalizationPack } from "@gkuis/gkp-base-widgets";
import "./ResponseplusEmptyPage.scss";

export type ResponseplusEmptyPageProps = {
  localizationPack: LocalizationPack;
  loading: boolean
}

export const ResponseplusEmptyPage = observer(({
                                                 localizationPack,
                                                 loading
                                               }: ResponseplusEmptyPageProps) => {

  const renderContent = () => {
    if (loading) {
      return <DHLLoading
          loading={true}
          name={"rpp-order-loading"} />;
    }
    return (
        <DHLHeadline type={"h5"}>
          {localizationPack.getLabel("order.pleaseSelectEkp")}
        </DHLHeadline>
    );
  };

  return <DialogFormContent className={"responseplus-empty-page"}>
    {renderContent()}
  </DialogFormContent>;
});