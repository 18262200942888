/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { DHLHeadline, DialogFormContent7030, ResourceDataStore } from "@gkuis/gkp-base-widgets";
import { observer } from "mobx-react-lite";
import { ResponseplusOrderStore } from "../../../../stores/ResponseplusOrderStore";
import "./OrderConfirm.scss";
import {OrderConfirmSidePanel} from "./OrderConfirmSidePanel";
import {OrderContentPanel} from "./OrderContentPanel";

export type OrderConfirmPageProps = {
  orderStore: ResponseplusOrderStore;
}

export const OrderConfirmPage = observer(({orderStore}: OrderConfirmPageProps) => {
  const resourceDataStore: ResourceDataStore = orderStore.baseStore.resourceDataStore as ResourceDataStore;
  const preamble = (
      <DHLHeadline type={"h5"}>
        {resourceDataStore.getLabel("order.page3-orderconfirm.headline")}
      </DHLHeadline>
  );
  const mainContent = (<OrderContentPanel orderStore={orderStore} showFrankingMarkPreview={true}/>);
  const sidePanel = <OrderConfirmSidePanel orderStore={orderStore} />;
  return (
      <DialogFormContent7030  className={"responseplus-order-page3-container"}
          preamble={preamble}
          leftContent70={mainContent}
          rightMarginal30={sidePanel} />
  );
});