/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { DHLFilteredComboBox, ResourceDataStore } from "@gkuis/gkp-base-widgets";
import { observer } from "mobx-react-lite";
import { ResponseplusCustomerProfileSelectStore } from "../../stores/ResponseplusCustomerProfileSelectStore";

export type ProfileSelectBoxProps = {
  profileSelectStore: ResponseplusCustomerProfileSelectStore
  resourceDataStore: ResourceDataStore
}

export const ProfileSelectBox = observer(({
                                   profileSelectStore,
                                   resourceDataStore
                                 }: ProfileSelectBoxProps) => {
  if (profileSelectStore.availableProfiles.length <= 1) {
    return null;
  }

  return (
    <DHLFilteredComboBox
      data={profileSelectStore.availableProfiles}
      limitToInputFieldWidth={false}
      clearOnFocus={true} autoCompleteOff={true}
      formField={profileSelectStore.profileSelectFormfield}
      icon={"group"}
      emptyListText={resourceDataStore.getLabel("order.emptyNoProfileForFilter")} />
  );

});